import { ComboBase } from "src/app/models/comboModel";
import { DatePipe, JsonPipe } from "@angular/common";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MessageService, TreeNode } from "primeng/api";
import { formatDate } from '@angular/common';
import { AtividadeOSParcelaVinculada } from "../models/atividade/atividade-os-parcela-vinculada";
import { MxlAtividadeFiltro } from "src/app/models/filtro.model";

@Injectable({
  providedIn: "root",
})
export class AtividadeOsParcelaVinculadaService {
  private environmentUrl: string = "";
  clearFiltersDemandas = new EventEmitter();

  constructor(private httpClient: HttpClient,
    public datepipe: DatePipe,
    private messageService: MessageService) {
    this.environmentUrl = environment.ApiAtv;
  }

  getOSVinculadas(
    numeroDemanda?: string,
    idAtividadePortifolio?: ComboBase,
    idAtividadeNatureza?: ComboBase,
    dataSolicitacaoFiltroInicial?: Date,
    dataSolicitacaoFiltroFinal?: Date,
    ordemDeServico?: number,
    idHierarquia?: TreeNode,
    idAtividadeEtapa?: ComboBase,
    idUsuarioSolicitante?: ComboBase,
    idUsuarioResponsavel?: ComboBase,
    idAtividadeStatus?: ComboBase,
    idAtividadeStatusOS?: ComboBase,
    idAtividadeStatusParcela?: ComboBase,

    idsAtividadeEtapa?: ComboBase[],
    idsAtividadeStatus?: ComboBase[],
    tipoDeConvenio?: string[],
    dataInicio?: Date,
    dataTermino?: Date,
    idsHierarquias?: TreeNode[]
  ): Observable<AtividadeOSParcelaVinculada[]> {
    let url = this.environmentUrl + "v1/Atividade/GetAtividades";

    let hierarquias = idsHierarquias;
    let listHierarquias: any;

    if (hierarquias != undefined) {
      hierarquias.forEach(element => {
        var hierarquiaTemp = {
          label: element.label, data: element.data, icon: element.icon, expandedIcon: element.expandedIcon,
          collapsedIcon: element.collapsedIcon, leaf: element.leaf, expanded: element.expanded,
          type: element.type, partialSelected: element.partialSelected, style: element.style,
          styleClass: element.styleClass, draggable: element.draggable, droppable: element.droppable,
          selectable: element.selectable, key: element.key
        };
        listHierarquias.push(hierarquiaTemp);
      });
    } else {
      listHierarquias = idsHierarquias != null ? idsHierarquias : null;
    }

    let queryString = this.getQueryString(
      numeroDemanda,
      idAtividadePortifolio,
      idAtividadeNatureza,
      dataSolicitacaoFiltroInicial,
      dataSolicitacaoFiltroFinal,
      ordemDeServico,
      idHierarquia,
      idAtividadeEtapa,
      idUsuarioSolicitante,
      idUsuarioResponsavel,
      idAtividadeStatus,
      idAtividadeStatusOS,
      idAtividadeStatusParcela,

      idsAtividadeEtapa ? JSON.stringify(idsAtividadeEtapa) : undefined,
      idsAtividadeStatus ? JSON.stringify(idsAtividadeStatus) : undefined,
      tipoDeConvenio ? tipoDeConvenio.toString() : undefined,
      dataInicio,
      dataTermino,
      listHierarquias ? JSON.stringify(listHierarquias) : undefined
    );

    if (queryString.length == 0 || queryString == 'tipoDeConvenio=Emendas,Demandas') {
      this.messageService.add({ severity: 'warn', summary: 'Pesquisa Serviços', detail: 'Selecione pelo menos um filtro ou o período.', life: 6000 });
      return this.httpClient.get<AtividadeOSParcelaVinculada[]>(url + "?numeroDemanda=-1");
    }

    url = url + "?" + queryString;
    return this.httpClient.get<AtividadeOSParcelaVinculada[]>(url);
  }

  public getQueryString(
    numeroDemanda?: string,
    idAtividadePortifolio?: ComboBase,
    idAtividadeNatureza?: ComboBase,
    dataSolicitacaoFiltroInicial?: Date,
    dataSolicitacaoFiltroFinal?: Date,
    ordemDeServico?: number,
    idHierarquia?: TreeNode,
    idAtividadeEtapa?: ComboBase,
    idUsuarioSolicitante?: ComboBase,
    idUsuarioResponsavel?: ComboBase,
    idAtividadeStatus?: ComboBase,
    idAtividadeStatusOS?: ComboBase,
    idAtividadeStatusParcela?: ComboBase,

    idsAtividadeEtapa?: string,
    idsAtividadeStatus?: string,
    tipoDeConvenio?: string,
    dataInicio?: Date,
    dataTermino?: Date,
    idsHierarquias?: string
  ): string {
    let queryString = "";

    queryString = this.getComposedQueryStringForStringValue(queryString, numeroDemanda, "numeroDemanda");
    queryString = this.getComposedQueryStringComboBaseValue(queryString, idAtividadePortifolio, "idAtividadePortifolio");
    queryString = this.getComposedQueryStringComboBaseValue(queryString, idAtividadeNatureza, "idAtividadeNatureza");
    queryString = this.getComposedQueryStringDateValue(queryString, dataSolicitacaoFiltroInicial, "dataSolicitacaoFiltroInicial");
    queryString = this.getComposedQueryStringDateValue(queryString, dataSolicitacaoFiltroFinal, "dataSolicitacaoFiltroFinal");
    queryString = this.getComposedQueryStringForNumberValue(queryString, ordemDeServico, "ordemDeServico");
    queryString = this.getComposedQueryStringTreeNodeValue(queryString, idHierarquia, "idHierarquia");
    queryString = this.getComposedQueryStringComboBaseValue(queryString, idAtividadeEtapa, "idAtividadeEtapa");
    queryString = this.getComposedQueryStringComboBaseValue(queryString, idUsuarioSolicitante, "idUsuarioSolicitante");
    queryString = this.getComposedQueryStringComboBaseValue(queryString, idUsuarioResponsavel, "idUsuarioResponsavel");
    queryString = this.getComposedQueryStringComboBaseValue(queryString, idAtividadeStatus, "idAtividadeStatus");
    queryString = this.getComposedQueryStringComboBaseValue(queryString, idAtividadeStatusParcela, "idAtividadeStatusParcela");
    queryString = this.getComposedQueryStringComboBaseValue(queryString, idAtividadeStatusOS, "idAtividadeStatusOS");

    queryString = this.getComposedQueryStringComboBaseListValue(queryString, idsAtividadeEtapa ? JSON.parse(idsAtividadeEtapa) : undefined, "idsAtividadesEtapas");
    queryString = this.getComposedQueryStringComboBaseListValue(queryString, idsAtividadeStatus ? JSON.parse(idsAtividadeStatus) : undefined, "idsAtividadesStatus");
    queryString = this.getComposedQueryStringForStringValue(queryString, tipoDeConvenio, "tipoDeConvenio");
    queryString = this.getComposedQueryStringForStringValue(queryString, dataInicio != null ? formatDate(dataInicio, 'yyyy-MM-dd', 'en-US') : null, "dataInicio");
    queryString = this.getComposedQueryStringForStringValue(queryString, dataTermino != null ? formatDate(dataTermino, 'yyyy-MM-dd', 'en-US') : null, "dataTermino");
    queryString = this.getComposedQueryStringTreeNodeListValue(queryString, idsHierarquias ? JSON.parse(idsHierarquias) : undefined, "idsHierarquias");

    return queryString;
  }

  private getComposedQueryStringForNumberValue(
    queryString: any,
    value: any,
    searchField: any
  ): string {
    if (value == null) {
      return queryString;
    }

    if (queryString.length > 0) {
      queryString = queryString + "&";
    }

    queryString = queryString + searchField + "=" + value;

    return queryString;
  }

  private getComposedQueryStringForStringValue(
    queryString: any,
    value: any,
    searchField: any
  ): string {
    if (value == null) {
      return queryString;
    }

    if (queryString.length > 0) {
      queryString = queryString + "&";
    }

    queryString = queryString + searchField + "=" + value;

    return queryString;
  }

  private getComposedQueryStringComboBaseValue(
    queryString: any,
    combo: any,
    searchField: any
  ): string {
    if (combo == null) {
      return queryString;
    }

    if (queryString.length > 0) {
      queryString = queryString + "&";
    }

    queryString = queryString + searchField + "=" + combo.value;

    return queryString;
  }

  private getComposedQueryStringDateValue(
    queryString: any,
    value: any,
    searchField: any
  ): string {
    if (value == null) {
      return queryString;
    }

    if (queryString.length > 0) {
      queryString = queryString + "&";
    }

    let formattedValue = this.datepipe.transform(
      value,
      "yyyy-MM-dd"
    );

    queryString = queryString + searchField + "=" + formattedValue;

    return queryString;
  }

  private getComposedQueryStringTreeNodeValue(queryString: string,
    combo: any,
    searchField: any
  ): string {
    if (combo == null || combo.key == null) {
      return queryString;
    }

    if (queryString.length > 0) {
      queryString = queryString + "&";
    }

    queryString = queryString + searchField + "=" + combo.key;

    return queryString;
  }

  private getComposedQueryStringComboBaseListValue(
    queryString: any,
    combo: any[],
    searchField: any
  ): string {
    if (combo == null) {
      return queryString;
    }

    if (queryString.length > 0) {
      queryString = queryString + "&";
    }

    queryString = queryString + searchField + "=" + combo.map(m => m.value);

    return queryString;
  }

  private getComposedQueryStringTreeNodeListValue(
    queryString: any,
    treeNode: any[],
    searchField: any
  ): string {
    if (treeNode == null) {
      return queryString;
    }

    if (queryString.length > 0) {
      queryString = queryString + "&";
    }

    queryString = queryString + searchField + "=" + treeNode.map(m => m.key);

    return queryString;
  }

  getOSVinculadasFiltro(filtro: any): Observable<AtividadeOSParcelaVinculada[]> {
    let url = this.environmentUrl + "v1/Atividade/GetAtividades";

    if (filtro == null) {
      return this.httpClient.get<AtividadeOSParcelaVinculada[]>(url + "?numeroDemanda=-1");
    } else if (filtro.mxlAtividadeFiltroMxlAtividades.length == 0 && (filtro.queryString.length == 0 || filtro.queryString == 'tipoDeConvenio=Emendas,Demandas')) {
      this.messageService.add({ severity: 'warn', summary: 'Pesquisa Serviços', detail: 'Selecione pelo menos um filtro ou o período.', life: 6000 });
      return this.httpClient.get<AtividadeOSParcelaVinculada[]>(url + "?numeroDemanda=-1");
    }

    if (filtro.mxlAtividadeFiltroMxlAtividades.length > 0) {
      url = url + "?idAtividadeFiltro=" + filtro.idAtividadeFiltro;
    } else {
      url = url + "?" + filtro.queryString;
    }
    return this.httpClient.get<AtividadeOSParcelaVinculada[]>(url);
  }
}