import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user';
import { jwtDecode } from 'jwt-decode';

@Injectable({ providedIn: 'root' })
export class UserService {
  private userName = '';
  private roles: Array<any> = [];
  private id = 0;
  private userSubject = new BehaviorSubject<User | null>(null);
  private idEmpresa = 0;
  private Func = '';
  private idHierarquia = 0;
  private hierarquia = '';

  constructor(private tokenService: TokenService) {
    this.tokenService.hasToken() && this.decodeAndNotify();
  }

  setToken(token: string) {
    this.tokenService.setToken(token);
    this.decodeAndNotify();
  }

  getUser() {
    return this.userSubject.asObservable();
  }
  getUserId() {
    return this.id;
  }

  private decodeAndNotify() {
    const token = this.tokenService.getToken();
    const user = jwtDecode(token) as User;
    //console.log(user);
    this.userName = user.Nome;
    this.id = user.nameid;
    this.roles = [];
    this.Func = user.Funcao;
    this.idEmpresa = user.IdEmpresa;
    this.idHierarquia = user.IdHierarquia;
    this.hierarquia = user.Hierarquia;

    //if(mails.includes(user.email)){
    //    this.roles.push('admin');
    //}
    //this.roles.push('participante');
    // if(user.perfis !=undefined)
    //   this.roles = user.perfis;

    if (user.role != undefined) {
      if (Array.isArray(user.role)) {
        this.roles = user.role;
      } else if (user.role != '') {
        this.roles.push(user.role);
      }
    }
    this.userSubject.next(user);
  }

  public logout() {
    this.tokenService.removeToken();
    this.userSubject.next(null);
  }

  isLogged() {
    return this.tokenService.hasToken();
  }

  getUserName() {
    return this.userName;
  }

  getRoles() {
    return this.roles;
  }

  hasPermission(role: string) {
    return this.roles.includes(role);
  }

  IsMax() {
    return this.Func == 'MAX';
  }

  getIdEmpresa() {
    return this.idEmpresa;
  }

  getIdHierarquia() {
    return this.idHierarquia;
  }

  getHierarquia() {
    return this.hierarquia;
  }
}
