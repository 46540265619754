import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ComboBase } from 'src/app/models/comboModel';

@Injectable({
    providedIn: 'root'
})
export class SituacaoComboService {
    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient) {
        this.environmentUrl = environment.ApiAtv;
    }

    getLista(idEtapa?: number) : Observable<ComboBase[]> {     
        if (idEtapa == null || idEtapa == undefined) {   
            idEtapa = 0;
        }
        
        let url = this.environmentUrl + "v1/AtividadeStatus/demanda/combo/"+idEtapa;
        return this.httpClient.get<ComboBase[]>(url);
    }
}