<div class='demo-app'>
  <div class='demo-app-main'>
    <button pButton [disabled]="pzSelecionado == 'real'"
      [style.backgroundColor]="pzSelecionado == 'real' ? '#135F9B' : '#2196F3'" type="button"
      (click)="changePrazo('real')" pButton label="Pz. Real" class="col-1 button p-button-primary mr-2"
      style="height: 25px; font-size: 11px;"></button>
    <button pButton [disabled]="pzSelecionado == 'excedido'"
      [style.backgroundColor]="pzSelecionado == 'excedido' ? '#135F9B' : '#2196F3'" type="button"
      (click)="changePrazo('excedido')" pButton label="Pz. Excedido" class="col-1 button p-button-primary mr-2"
      style="height: 25px; font-size: 11px;"></button>

    <full-calendar *ngIf='calendarVisible' [options]='calendarOptions' style="min-height: 1280px;">
      <ng-template #eventContent let-arg>
        <b>{{ arg.event.title }}</b>
        <i>{{ arg.timeText }}</i>
      </ng-template>
    </full-calendar>
  </div>
</div>