import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AtividadeOsParcelaVinculadaService } from 'src/app/core/services/atividade-os-parcela-vinculada.service';
import { AtividadeStatusService } from 'src/app/core/services/atividade-status.service';
import { ComboBase } from 'src/app/models/comboModel';

@Component({
  selector: 'sgc-atividade-status-multiselect',
  templateUrl: './atividade-status-multiselect.component.html',
  styleUrls: ['./atividade-status-multiselect.component.scss']
})
export class AtividadeStatusMultiselectComponent implements OnInit, OnDestroy {

  list!: ComboBase[];
  @Input() selectedItem!: ComboBase[];
  @Output() itemSelecionado = new EventEmitter();

  constructor(private atividadeStatusService: AtividadeStatusService,
    private atividadeOsParcelaVinculadaService: AtividadeOsParcelaVinculadaService
  ) { }

  ngOnInit(): void {
    this.getLista();

    this.atividadeOsParcelaVinculadaService.clearFiltersDemandas
      .subscribe(
        () => {
          this.selectedItem = [];
        }
      );
  }

  ngOnDestroy(): void {
  }

  getLista() {
    this.atividadeStatusService.getListaStatusDemanda()
      .subscribe(
        (res: any) => {
          if (res) {
            for (let i = 0; i < res.length; i++) {
              res[i].text = res[i].code + ' - ' + res[i].text;
            }
            this.list = res.sort((a: any, b: any) => a.text.localeCompare(b.text));
          }
        },
        (err: any) => {
          console.log(`AtividadeStatusMultiSelect - getLista ` + err);
        }
      );
  }

  onChange(event: any) {
    if (event.value !== null && event.value !== undefined) {
      this.itemSelecionado.emit(event.value);
    }
  }
}