import { Component, OnInit, OnDestroy } from '@angular/core';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { NotificationsService } from './notifications.service';

@Component({
  selector: 'max-notificacoes',
  template: ` <p-messages [value]="message" *ngIf="showMessage"></p-messages>
    <p-toast></p-toast>`,
})
export class NotificationsComponent implements OnInit, OnDestroy {
  message: Message[] = [];
  toast: Message[] = [];
  messageSubscription!: Subscription;
  toastSubscription!: Subscription;
  showMessage: boolean = false;
  showToast: boolean = false;

  constructor(
    private notificationsService: NotificationsService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.subscribeToMessageNotifications();
    this.subscribeToToastlNotifications();
  }

  subscribeToToastlNotifications() {
    this.toastSubscription =
      this.notificationsService.toastNotificationChange.subscribe(
        (notification: Message) => {
          this.showMessage = false;

          this.messageService.add({
            severity: notification.severity,
            summary: notification.summary,
            detail: notification.detail,
            life: 10000,
          });
          //        console.log("notificando2");
        },
      );
  }

  subscribeToMessageNotifications() {
    this.messageSubscription =
      this.notificationsService.messageNotificationChange.subscribe(
        (notification: any) => {
          this.showMessage = true;
          console.log('notificando3');
          if (notification !== undefined) {
            notification.forEach((element: any) => {
              this.message.push(element);
              console.log(this.message);
            });

            setTimeout(() => {
              this.message.shift();
            }, 8000);
          }
        },
      );
  }

  ngOnDestroy() {
    //console.log("destroy");
    this.messageSubscription.unsubscribe();
    this.toastSubscription.unsubscribe();
  }
}
