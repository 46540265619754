<div class="c-menu" data="menu">
  <div class="c-menu__list" data="menu">
    <ng-container *ngFor="let item of menu">
      <div
        class="c-menu__list--item"
        [ngClass]="item.selected ? 'selected' : 'unselected'"
        data="menu"
        (click)="onSelectItem(item)"
      >
        <i data="menu" [class]="item.icon"></i>

        <ng-container *ngIf="item.activeSubMenu">
          <div class="sub-list" data="menu">
            <ng-container *ngFor="let submenu of item?.subMenu">
              <div
                class="sub-list__item"
                data="menu"
                (click)="onSelectSubItem(submenu)"
              >
                <i data="menu" [class]="submenu.icon"></i>
                {{ submenu.label }}
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
