import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AtividadeStatusService } from 'src/app/core/services/atividade-status.service';
import { Status } from 'src/app/models/status';


@Component({
    selector: 'sgc-status-combo',
    templateUrl: './atividade-status-combo.component.html'    
})
export class AtividadeStatusComboComponent implements OnInit {

    public listaStatus: Status[] = [];
    public selectedItem: Status = {} as Status;
    @Input() idAtividadeStatus: number = 0;
    @Input() class: string = '';
    @Input() filterStatusType: string = '';
    @Output() itemSelecionado = new EventEmitter();

    constructor(private statusService: AtividadeStatusService) {}

    ngOnInit(): void {
        this.getStatus();
    }
    
    getStatus() {
        this.statusService.getListaStatus()
            .subscribe(
                (res) => {
                    if (res) {
                        if (this.filterStatusType != '' && this.filterStatusType != null) {
                            this.listaStatus = res.filter(status => status.atividadeStatusTipo?.toUpperCase().startsWith(this.filterStatusType));
                        } else {
                            this.listaStatus = res;
                        }
                        this.selectedItem = this.listaStatus.find(status => status.idAtividadeStatus === this.idAtividadeStatus) || {} as Status;
                    }
                },
                err => {
                    console.log(`StatusList ` + err);        
                }
            );
    }    

    onChange(event: any) {
        console.log('event :' + event);
        console.log(event.value);
        this.itemSelecionado.emit(event.value);
    }
}