import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable, OnDestroy } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UiService implements OnDestroy {

    private readonly unsubscribe$: Subject<void>;

    constructor(private breakpointObserver: BreakpointObserver) {
        this.unsubscribe$ = new Subject();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onDetectDevice(size: number): Observable<boolean> {
        return this.breakpointObserver.observe(`(max-width: ${size}px)`).pipe(map((data: { matches: boolean }) => data.matches));
    }

    onDetectPhone(): Observable<boolean> {
        return this.breakpointObserver.observe('(max-width: 600px)').pipe(map((data: { matches: boolean }) => data.matches));
    }

    onDetectTablet(): Observable<boolean> {
        return this.breakpointObserver.observe('(max-width: 768px)').pipe(map((data: { matches: boolean }) => data.matches));
    }

    onDetectDesktop(): Observable<boolean> {
        return this.breakpointObserver.observe('(max-width: 1200px)').pipe(map((data: { matches: boolean }) => data.matches));
    }
}