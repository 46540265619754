import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventApi, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { DatePipe, formatDate } from '@angular/common';
import { AtividadeService } from 'src/app/core/services/atividade.service';

@Component({
    selector: 'sgc-gantt-consolidado',
    templateUrl: './gantt-consolidado.component.html',
    styleUrls: ['./gantt-consolidado.component.scss']
})
export class GanttConsolidadoComponent implements OnInit {

    @Input() demandas: any[] = [];

    resources: any[] = [];
    events: any[] = [];
    calendarOptions!: CalendarOptions;
    calendarVisible: boolean = false;

    currentEvents: EventApi[] = [];
    eventGuid = 9999;

    duration = 0;
    index = 0;

    pzSelecionado: string = 'real';

    constructor(
        private changeDetector: ChangeDetectorRef,
        private atividadeService: AtividadeService,
        public datepipe: DatePipe
    ) { }

    ngOnInit(): void {
        this.getGanttAtividade();

        this.atividadeService.demandaGanttOnLoad
            .subscribe(data => {
                if (data) {
                    this.calendarVisible = false;

                    this.resources = [];
                    this.events = [];

                    if (data.demandas.length > 0) {

                        this.demandas = data.demandas;
                        this.getGanttAtividade();
                    }
                }
            });
    }

    handleCalendarToggle() {
        this.calendarVisible = !this.calendarVisible;
    }

    handleWeekendsToggle() {
        const { calendarOptions } = this;
        calendarOptions.weekends = !calendarOptions.weekends;
    }

    handleDateSelect(selectInfo: DateSelectArg) {
        const title = prompt('Please enter a new title for your event');
        const calendarApi = selectInfo.view.calendar;

        calendarApi.unselect(); // clear date selection

        if (title) {
            calendarApi.addEvent({
                id: this.createEventId(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            });
        }
    }

    handleEventClick(clickInfo: EventClickArg) {
        if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
            clickInfo.event.remove();
        }
    }

    handleEvents(events: EventApi[]) {
        this.currentEvents = events;
        this.changeDetector.detectChanges();
    }

    loadCalendar() {
        var dataNow = new Date();
        this.calendarOptions = {
            buttonText: {
                today: 'hoje',
                month: 'mês',
                year: 'ano',
                week: 'semana',
                day: 'dia',
                list: 'lista',
                resourceTimelineYear: 'ano'
            },
            timeZone: 'America/Sao_Paulo',
            now: dataNow,
            locale: 'pt-br',
            aspectRatio: 1.5,
            expandRows: false,
            height: '100%',
            eventOverlap: false,
            eventMouseEnter: function (calEvent: any) {

                calEvent.el.title = 'Etapa: ' + calEvent.event.extendedProps.etapa + '\n' +
                    'Dias: ' + (calEvent.event.extendedProps.dias == null ? '0' : calEvent.event.extendedProps.dias);
            },
            eventDidMount(arg: any) {
                if (arg.view.type == 'resourceTimelineYear') {

                    let calendarMonthStart = new Date(arg.event.start.getFullYear() + '-' + (arg.event.start.getMonth() + 1) + '-01');

                    let dateStart = new Date(arg.event.start);
                    let dateEnd = new Date(arg.event.end);
                    let timeDiff = Math.abs(dateEnd.getTime() - dateStart.getTime());
                    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

                    let marginLeftTimeDiff = Math.abs(dateStart.getTime() - calendarMonthStart.getTime());
                    let marginLeftDiffDays = Math.ceil(marginLeftTimeDiff / (1000 * 3600 * 24));

                    arg.el.style.width = diffDays.toString() + 'px';
                    arg.el.style.marginLeft = (marginLeftDiffDays).toString() + 'px';
                } else if (arg.view.type == 'resourceTimelineMonth') {

                    var date = new Date();
                    let numeroDiasMesAtual = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
                    let larguraTotal = numeroDiasMesAtual * 30;

                    let slotWidth = Math.round((arg.event.extendedProps.dias * larguraTotal) / arg.event.extendedProps.duration);

                    arg.el.style.width = slotWidth.toString() + 'px';
                } else if (arg.view.type == 'listYear') {
                    arg.el.querySelector('.fc-list-event-title b').innerHTML += ` ${arg.event.extendedProps.etapa}`
                }
            },
            displayEventTime: false,
            views: {
                resourceTimelineYear: {
                    duration: { years: this.duration }, // Or whatever value you want.
                    slotDuration: { months: 1 },
                    slotLabelInterval: { months: 1 },
                    slotMaxTime: '00:15:00',
                    slotWidth: "5%",
                    eventOverlap: true,
                    minTime: '00:00:00',
                    maxTime: "24:00:00",
                    slotEventOverlap: false
                },
                resourceTimelineMonth: {
                    duration: { months: 1 }, // Or whatever value you want.
                    slotDuration: { months: 1 },
                    slotLabelInterval: { days: 1 },
                    slotMaxTime: '00:15:00',
                    slotWidth: "5%",
                    eventOverlap: true,
                    minTime: '00:00:00',
                    maxTime: "24:00:00",
                    slotEventOverlap: true,
                    slotLabelFormat: [
                    ]
                },
                resourceTimelineWeek: {
                    slotDuration: { days: 7 }
                },
                resourceTimelineDay: {
                    slotDuration: { hours: 1 },
                    minTime: '00:00:00',
                    maxTime: "24:00:00",
                    slotLabelFormat: [

                    ]
                }
            },
            plugins: [
                interactionPlugin,
                dayGridPlugin,
                timeGridPlugin,
                listPlugin,
                resourceTimelinePlugin
            ],
            headerToolbar: {
                left: '',
                center: '',
                right: ''
            },
            initialView: 'resourceTimelineMonth',
            editable: true,
            selectable: true,
            selectMirror: true,
            dayMaxEvents: true,

            resourceAreaWidth: "37%",

            resourceLabelDidMount: function (info: any) {
                info.el.style.color = info.resource.extendedProps.cor;
            },

            refetchResourcesOnNavigate: true,

            resourcesInitiallyExpanded: false,
            resourceOrder: 'icone',
            resourceAreaColumns: [
                {
                    field: 'etapa',
                    headerContent: 'Etapa',
                    width: 270
                },
                {
                    field: 'prazoPrevTot',
                    //headerContent: 'Pz. Objetivo',
                    cellDidMount: function (info) {
                        info.el.style.textAlign = "center";
                    },
                    headerDidMount: function (info) {
                        info.el.innerHTML =

                            '<div class="fc-datagrid-cell-frame">' +
                            '<div class="fc-datagrid-cell-cushion fc-scrollgrid-sync-inner">' +
                            '<span class="fc-datagrid-cell-main">Pz. Objetivo</span>' +
                            '</div>' +
                            '<div class="fc-datagrid-cell-resizer"></div>' +
                            '</div>';
                    }
                },
                {
                    field: 'prazoRealTot',
                    headerContent: 'Pz. Real',
                    cellDidMount: function (info) {
                        info.el.style.textAlign = "center";
                    }
                },
                {
                    field: 'prazoTot',
                    headerContent: 'Pz. Excedido',
                    cellDidMount: function (info: any) {
                        info.el.style.textAlign = "center";
                        if (info.resource.extendedProps.prazoTot > 0) {
                            info.el.style.color = "#D12608";
                        } else {
                            info.el.style.color = "#0F8C05";
                        }
                    },
                }
            ],
            resources: this.resources,
            schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
            events: this.events,
        };

        this.calendarVisible = true;
    }

    getGanttAtividade() {

        if (this.demandas !== undefined && this.demandas != null && this.demandas.length > 0) {
            let demandas = this.demandas;

            let maxItem: any;
            if (this.pzSelecionado == 'objetivo') {
                maxItem = demandas.reduce(function (p, c) {
                    return (p && p.prazoPrevTot > c.prazoPrevTot) ? p : c;
                });
            } else if (this.pzSelecionado == 'real') {
                maxItem = demandas.reduce(function (p, c) {
                    return (p && p.prazoRealTot > c.prazoRealTot) ? p : c;
                });
            } else if (this.pzSelecionado == 'excedido') {
                maxItem = demandas.reduce(function (p, c) {
                    return (p && p.prazoTot > c.prazoTot) ? p : c;
                });
            }

            if (this.pzSelecionado == 'objetivo') {
                this.duration = maxItem === undefined || maxItem == null ? 0 : maxItem.prazoPrevTot;
            } else if (this.pzSelecionado == 'real') {
                this.duration = maxItem === undefined || maxItem == null ? 0 : maxItem.prazoRealTot;
            } else if (this.pzSelecionado == 'excedido') {
                this.duration = maxItem === undefined || maxItem == null ? 0 : maxItem.prazoTot;
            }

            for (let i = 0; i < demandas.length; i++) {
                let childrens = [];
                let id = this.createEventId();

                this.resources.push(
                    {
                        id: i,
                        title: demandas[i].etapa,
                        etapa: demandas[i].etapa,
                        cor: demandas[i].etapaCor,
                        prazoPrevTot: Math.round(demandas[i].prazoPrevTot).toString(),
                        prazoRealTot: Math.round(demandas[i].prazoRealTot).toString(),
                        prazoTot: Math.round(demandas[i].prazoTot).toString()
                    }
                );

                const TODAY_STR = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

                var date = new Date();
                let dataInicio: Date | null = new Date(date.getFullYear(), date.getMonth(), 1);
                let dataFim: Date | null = new Date(TODAY_STR);

                let numeroDiasMesAtual = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
                let slotDuration = 0;

                if (this.pzSelecionado == 'objetivo') {
                    slotDuration = Math.round((demandas[i].prazoPrevTot * numeroDiasMesAtual) / this.duration);
                } else if (this.pzSelecionado == 'real') {
                    slotDuration = Math.round((demandas[i].prazoRealTot * numeroDiasMesAtual) / this.duration);
                } else if (this.pzSelecionado == 'excedido') {
                    slotDuration = Math.round((demandas[i].prazoTot * numeroDiasMesAtual) / this.duration);
                }

                dataFim.setDate(dataInicio.getDate() + (slotDuration === undefined || slotDuration == null ? 0 : slotDuration)); // Adiciona número de dias

                if (dataInicio == dataFim) {
                    dataInicio = null;
                    dataFim = null;
                }

                this.events.push(
                    {
                        id: this.createEventId(),
                        resourceId: i,
                        title: this.pzSelecionado == 'objetivo' ? Math.round(demandas[i].prazoPrevTot) : (this.pzSelecionado == 'real' ? Math.round(demandas[i].prazoRealTot) : this.pzSelecionado == 'excedido' ? Math.round(demandas[i].prazoTot) : 0),
                        etapa: demandas[i].etapa,
                        start: dataInicio,
                        end: dataFim,
                        className: 'item_event expected',
                        color: demandas[i].etapaCor == null ? '#b0adad' : demandas[i].etapaCor,
                        extendedProps: {
                            description: '',
                            dataInicio: new Date(),
                            dataFim: new Date(),
                            dias: this.pzSelecionado == 'objetivo' ? Math.round(demandas[i].prazoPrevTot) : (this.pzSelecionado == 'real' ? Math.round(demandas[i].prazoRealTot) : this.pzSelecionado == 'excedido' ? Math.round(demandas[i].prazoTot) : 0),
                            duration: this.duration
                        }
                    }
                );
            }

            this.loadCalendar();
        }
    }

    createEventId() {
        return String(this.eventGuid++);
    }

    changePrazo(tipo: string) {
        this.pzSelecionado = tipo;
        this.resources = [];
        this.events = [];
        this.calendarVisible = false;
        this.getGanttAtividade();
        this.calendarVisible = true;
    }
}