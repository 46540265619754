<div class="card">
    <div class="formgroup-inline">
        <div class="col-4">
            <!-- <div style="width: 100%;" class="text-center"><h2>Etapas</h2></div> -->
            <div *ngIf="atividade !== undefined" class="text-center" style="font-size: 12px;">
                <table class="table-ciclo-de-vida" style="width: 100%;">
                    <tr>
                        <td colspan="2" class="table-sem-borda font-bold text-center" style="font-size: 14px;">Demanda:
                            {{atividade.numero}}</td>
                    </tr>
                    <tr>
                        <td colspan="2" class="table-sem-borda font-bold text-center">Ciclo de vida do convênio</td>
                    </tr>
                    <tr>
                        <td class="table-sem-borda text-left">
                            <label class="font-bold">Portfólio:</label>
                            <label class="font-normal"> {{atividade.portfolio}}</label>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-sem-borda font-bold text-left">
                            <label class="font-bold">Valor Total:</label>
                            <label class="font-normal"> {{atividade.valorTotal == null ? 'R$ 0,00' :
                                atividade.valorTotal.toLocaleString('pt-br',{style: 'currency', currency:
                                'BRL'})}}</label>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td class="table-sem-borda font-bold">Valor Estado:</td>
                        <td class="table-sem-borda">{{atividade.valorEstado == null ? 'R$ 0,00' : atividade.valorEstado.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</td>
                    </tr> -->
                    <tr>
                        <td class="table-sem-borda font-bold text-left">
                            <label class="font-bold">Início da demanda:</label>
                            <label class="font-normal"> {{atividade.dataCriacao | date: 'dd/MM/yyyy'}}</label>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-sem-borda font-bold text-left">
                            <label class="font-bold">Etapa atual: </label>
                            <span [class]="'status-badge'"
                                [style]="{'background-color': '' + atividade.corEtapa + '', 'color': '#DDDDDD'}">{{atividade.etapa}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-sem-borda font-bold text-left">
                            <label class="font-bold">Data última tramitação:</label>
                            <label class="font-normal"> {{atividade.dataUltimaTramitacao | date: 'dd/MM/yyyy'}}</label>
                        </td>
                    </tr>
                </table>
                <table style="width: 100%;">
                    <tr style="background-color: rgb(221, 221, 221);">
                        <td>Até a data</td>
                        <td>Prazo objetivo</td>
                        <td>Prazo real</td>
                        <td>Prazo vencido</td>
                    </tr>

                    <tr *ngFor="let resp of responsabilidades;">
                        <td>{{resp?.nome?.replace('PREFEITURA', 'Prefeitura')?.replace('SCME',
                            'Subsecretaria')?.replace('INDEFINIDO', 'Indefinido')}}</td>
                        <td>{{resp?.prazoPrevisto}}</td>
                        <td>{{resp?.prazoRealizado}}</td>
                        <td>{{resp?.prazoVencido}}</td>
                    </tr>
                    <!-- <tr>
                        <td>Subsecretaria</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr> -->
                    <tr>
                        <td>Total</td>
                        <td>{{totalPrazoPrevisto}}</td>
                        <td>{{totalPrazoRealizado}}</td>
                        <td>{{totalPrazoVencido}}</td>
                    </tr>
                </table>
            </div>
            <div style="display: block; height: 50vh; overflow-y: scroll; margin: 10px 0 0 0;">
                <p-timeline [value]="etapas.atividadeEtapaPlanejadaModel" styleClass="customized-timeline">
                    <ng-template pTemplate="marker" let-etapa>
                        <span class="timeline-custom" [style.backgroundColor]="etapa.cor">
                            <i ngClass="pi pi-circle"></i>
                        </span>
                    </ng-template>
                    <ng-template pTemplate="content" let-etapa>
                        <small *ngIf="atividade !== undefined" [style.color]="etapa.cor" style="font-size: 12px;"><a
                                href="javascript:void(0)" [style.color]="etapa.cor"
                                (click)="abrirDetalheEtapa(etapa)">{{etapa.etapa}}</a></small><br>
                        <small *ngIf="atividade !== undefined && etapa.etapa == atividade.etapa"
                            [style.color]="etapa.cor" style="font-size: 14px;">Situação:
                            {{atividade.situacao}}<br></small>
                        <table>

                            <tr *ngFor="let hist of etapa.datasEtapa;">
                                <td class="table-sem-borda" colspan="3">
                                    <small *ngIf="hist.dataInicio != null"
                                        style="font-size: 12px; font-weight: normal;"><label
                                            style="font-size: 12px; font-weight: bold;">Início:</label> {{
                                        hist.dataInicio | date:'dd/MM/yyyy'}}</small>
                                    <small *ngIf="hist.dataFim != null"
                                        style="font-size: 12px; font-weight: normal;"><label
                                            style="font-size: 12px; font-weight: bold;">&nbsp;&nbsp;&nbsp;Fim:</label>
                                        {{ hist.dataFim | date:'dd/MM/yyyy'}}</small>
                                </td>
                            </tr>


                            <tr>
                                <td class="table-sem-borda"><small style="font-size: 12px;"><label
                                            style="font-size: 12px; font-weight: bold;">Prazo real:</label><label
                                            style="font-size: 12px; font-weight: normal;">
                                            {{etapa.prazoReal}}</label></small></td>
                                <td class="table-sem-borda"><small style="font-size: 12px;"><label
                                            style="font-size: 12px; font-weight: bold;">Prazo objetivo:</label><label
                                            style="font-size: 12px; font-weight: normal;">
                                            {{etapa.prazoObjetivo}}</label></small></td>
                                <td class="table-sem-borda"><small style="font-size: 12px;"><label
                                            style="font-size: 12px; font-weight: bold;">Prazo vencido:</label><label
                                            [ngStyle]="{'color': (etapa.desvio > 0 ) ? 'red' : 'black'}"
                                            style="font-size: 12px; font-weight: normal;">
                                            {{etapa.desvio}}</label></small></td>
                            </tr>

                        </table>
                    </ng-template>
                    <ng-template pTemplate="opposite" let-etapa>
                    </ng-template>
                </p-timeline>
            </div>
        </div>
        <div [style.display]="displayDialogEtapas == true ? 'block' : 'none'" class="col-8">
            <!-- <div class="p-field" style="float: right;">
                <button type="button" (click)="closeDetailEtapa()" pButton icon="pi pi-angle-left" label="Fechar / Voltar"  style="width: 210px; margin-top:15px; margin-left: 15px;" class="button p-button-warning"></button>
            </div>   -->
            <div class="grid" style="width: 100%;">
                <div *ngIf="atividade !== undefined" class="col-12">
                    <table>
                        <tr>
                            <td [style]="{'background-color':''+etapaCor+'', 'color': 'white'}" colspan="6">
                                {{nomeEtapa}}
                            </td>
                        </tr>
                        <!-- <tr>
                            <td class="font-bold">Demanda:</td>
                            <td>{{atividade.numero}}</td>
                        </tr>
                        <tr>
                            <td class="font-bold">Portfólio:</td>
                            <td>{{atividade.portfolio}}</td>
                        </tr>
                        <tr>
                            <td class="font-bold">Valor Total:</td>
                            <td>{{atividade.valorTotal == null ? 'R$ 0,00' : atividade.valorTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</td>
                        </tr>
                        <tr>
                            <td class="font-bold">Valor Estado:</td>
                            <td>{{atividade.valorEstado == null ? 'R$ 0,00' : atividade.valorEstado.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</td>
                        </tr> -->
                    </table>
                </div>
                <div class="col-12">
                    <p-table #dt [value]="listaDetalheEtapa" dataKey="idAtividadeEtapa" [paginator]="true" [rows]="50"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        [rowsPerPageOptions]="[50,100,200]"
                        styleClass="p-datatable-responsive-demo p-datatable-striped p-datatable-gridlines p-datatable-gridlines"
                        [responsive]='true' rowGroupMode="subheader" groupRowsBy="situacao">
                        <ng-template pTemplate="header">
                            <tr style="font-size: 12px;">
                                <th colspan="6" class="text-center">Tramitações Fluxo Demandas</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="groupheader" let-etapa>
                            <tr pRowGroupHeader>
                                <td colspan="6">
                                    Situação: <span [style.color]="etapa.situacaoCor" style="font-size: 14px;"
                                        class="font-bold ml-2">{{etapa.situacao}}</span>
                                </td>
                            </tr>
                            <tr style="font-size: 14px;">
                                <td colspan="2" class="font-bold">Data Início</td>
                                <td colspan="2" class="font-bold">Data Fim</td>
                                <td colspan="2" class="font-bold" style="text-align: center">Prazo Dias</td>
                            </tr>
                        </ng-template>
                        <ng-template *ngIf="false" pTemplate="groupfooter" let-etapa>
                            <tr class="p-rowgroup-footer">
                                <td colspan="2"></td>
                                <td colspan="2" style="text-align: right">Prazo Real:</td>
                                <td colspan="2">{{calculateSituacaoTotal(etapa.situacao)}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-etapa let-rowIndex="rowIndex">
                            <tr style="font-size: 12px;">
                                <td colspan="2">{{etapa.dataInicio | date: 'dd/MM/yyyy'}}</td>
                                <td colspan="2">{{etapa.dataFim | date: 'dd/MM/yyyy'}}</td>
                                <td colspan="2" style="text-align: center">{{etapa.prazoDias}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer" let-etapa>
                            <tr>
                                <td [style]="{'background-color':''+etapaCor+'', 'color': 'white'}" colspan="6">
                                    Resumo da Etapa
                                </td>
                            </tr>
                            <tr>
                                <td>Data Início:</td>
                                <td>{{dataInicioEtapa | date: 'dd/MM/yyyy'}}</td>
                                <td>Prazo objetivo</td>
                                <td>Prazo real</td>
                                <td>Prazo Diligência</td>
                                <td>Prazo vencido</td>
                            </tr>
                            <tr>
                                <td>Data Fim:</td>
                                <td>{{dataFimEtapa | date: 'dd/MM/yyyy'}}</td>
                                <td>{{prazoObjetivoEtapa}}</td>
                                <td>{{prazoRealEtapa}}</td>
                                <td>{{prazoRealDiligencia}}</td>
                                <td><label
                                        [ngStyle]="{'color': (desvioEtapa && desvioEtapa > 0 ) ? 'red' : 'black'}">{{desvioEtapa}}</label>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>