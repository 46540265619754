import { Component, Input, OnInit } from '@angular/core';
import { AtividadeDetalhe } from 'src/app/core/models/atividade/atividade-detalhe';

@Component({
    selector: 'sgc-atividade-parcela-detail',
    templateUrl: './atividade-parcela-detail.component.html'
})
export class AtividadeParcelaDetailComponent implements OnInit {
    @Input() atividadeParcela!: AtividadeDetalhe;
    parcela: string = '';
    referencia: string = '';
    estadoAtual: string = '';

    ngOnInit(): void {
        let dadosParcela: string[];
        if (this.atividadeParcela.observacao !== undefined && this.atividadeParcela.observacao != null) {
            let dadosParcela = this.atividadeParcela.observacao.split('|');
            if (dadosParcela.length > 0) {
                this.parcela = dadosParcela[0].replace("Parcela: ", "");
            }
            if (dadosParcela.length > 1) {
                this.referencia = dadosParcela[1].replace("Referência: ", "");
            }
            if (dadosParcela.length > 2) {
                let numeroParcela = dadosParcela[0].replace("Parcela: ", "");
                let estadoAtual = dadosParcela[2].replace("Fluxo: ", "").split("Estado atual: ");
                this.estadoAtual = estadoAtual[1];
            }
        }
    }
}