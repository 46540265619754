import { ComboBase } from 'src/app/models/comboModel';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Caracteristica } from 'src/app/models/caracteristica';

@Injectable({
    providedIn: 'root'
  })
  export class CaracteristicaService {
  
    private environmentUrl: string = '';
    dialogCaracteristica = new EventEmitter();
    
    constructor(private httpClient: HttpClient) {
      this.environmentUrl = environment.ApiCore+"v1/Caracteristica/";
    }

    getComboCaracteristica() : Observable<ComboBase[]> {
      let url=this.environmentUrl+"GetListaCombo";      
      return this.httpClient.get<ComboBase[]>(url);
    }

    getCaracteristica() : Observable<Caracteristica[]>{
      let url = this.environmentUrl + "GetLista";      
      return this.httpClient.get<Caracteristica[]>(url);
    }
    
    setCaracteristica(caracteristica: Caracteristica) {

      let params = {
        IdCaracteristica: caracteristica.idCaracteristica,
        IdEmpresa: caracteristica.idEmpresa,
        Sigla: caracteristica.sigla,
        Nome: caracteristica.nome,
        FlagTerceiro: caracteristica.flagTerceiro,
        FlagAtivo: caracteristica.flagAtivo
      };

      let url = this.environmentUrl + "SetItem";
      return this.httpClient.post<number>(url, params);        
    }

    delCaracteristica(idCaracteristica: number) {
      
      let url = this.environmentUrl + "DelItem/" + idCaracteristica;
      return this.httpClient.delete<boolean>(url);
    }
}