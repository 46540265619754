<p-dropdown 
    [options]="listaStatus" 
    [(ngModel)]="selectedItem" 
    placeholder="Selecione o Status" 
    optionLabel="nome" 
    [showClear]="true"
    class="class"
    autoWidth="false" 
    [style]="{'width':'100%'}"
    (onChange)="onChange($event)">
</p-dropdown>