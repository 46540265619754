import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { UsuarioService } from 'src/app/core/services/usuario.service';

import { ComboBase } from 'src/app/models/comboModel';

@Component({
    selector: 'sgc-usuario-secretaria-combo',
    templateUrl: './usuario-secretaria-combo.component.html',
    styleUrls: ['./usuario-secretaria-combo.component.scss']
})

export class UsuarioSecretariaComboComponent implements OnInit {

    public lista!: ComboBase[];
    public selectedItem!: any;

    @Input() idItem!: number;
    @Input() idHierarquia!: number;
    @Input() idEmpresaTerceira!: number;
    @Input() disabled: boolean = false;
    @Output() itemSelecionado = new EventEmitter();
    @Output() updateCombo = new EventEmitter();

    constructor(private usuarioService: UsuarioService) { }

    ngOnInit(): void {
        this.getUsuarioSecretariaCombo();
    }

    ngOnChanges(): void {
        this.getUsuarioSecretariaCombo();
    }

    getUsuarioSecretariaCombo() {
        this.usuarioService.getUsuarioSecretariaCombo().subscribe(
            {
                next: (res: any) => {
                    if (res)
                        this.lista = res;
                    if (this.lista !== null)
                        this.selectedItem = this.lista.find(x => x.value == this.idItem?.toString());
                },
                error: (err) => {
                    console.log(`UsuarioSecretariaCombo - getLista : ${err}.`);
                }
            });
    }

    feedback(selectedItem: any) {
        this.itemSelecionado.emit(selectedItem.value);
    }

}
