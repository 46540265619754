import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AtividadeDetalhe } from 'src/app/core/models/atividade/atividade-detalhe';
import { AtividadeEtapa, AtividadeEtapaDetalhe, AtividadeEtapaHistorico, Responsabilidade } from 'src/app/core/models/atividade/atividade-etapa';
import { AtividadeService } from 'src/app/core/services/atividade.service';

@Component({
    selector: 'sgc-ciclo-de-vida',
    templateUrl: './ciclo-de-vida.component.html',
    styleUrls: ['ciclo-de-vida.component.scss']
})
export class CicloDeVidaComponent implements OnInit {

    @Input() idAtividade!: number;
    @Input() atividade!: AtividadeDetalhe;

    etapas = {} as AtividadeEtapaDetalhe;
    displayDialogEtapas!: boolean;
    listaDetalheEtapa!: AtividadeEtapaHistorico[];
    nomeEtapa: string = '';
    dataInicioEtapa?: Date;
    dataFimEtapa?: Date;
    prazoRealEtapa?: number;
    prazoObjetivoEtapa?: number;
    prazoRealDiligencia?: number;
    desvioEtapa?: number;
    etapaCor?: string;
    responsabilidades!: Responsabilidade[];
    totalPrazoRealizado?: number;
    totalPrazoPrevisto?: number;
    totalPrazoVencido?: number;

    constructor(
        private atividadeService: AtividadeService,
        private activatedRoute: ActivatedRoute,
        private config: DynamicDialogConfig,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.getAtividadeEtapa();

        this.atividadeService.demandaOnLoad
            .subscribe((data: any) => {
                if (data) {
                    if (data.idAtividade > 0) {
                        this.idAtividade = data.idAtividade;
                        this.getAtividadeEtapa();
                    }
                }
            });
    }

    getAtividadeEtapa() {
        this.atividadeService.getAtividadeEtapa(this.idAtividade)
            .subscribe(
                (atvEtapa: any) => {
                    if (atvEtapa != null) {
                        this.etapas = atvEtapa;
                        if (this.atividade !== undefined) {
                            let etapa = atvEtapa.atividadeEtapaPlanejadaModel.find((f: any) => f.etapaAtual == true);
                            this.responsabilidades = atvEtapa.responsabilidade;
                            this.totalPrazoPrevisto = atvEtapa.responsabilidade.reduce((sum: any, current: any) => sum + current.prazoPrevisto, 0);
                            this.totalPrazoRealizado = atvEtapa.responsabilidade.reduce((sum: any, current: any) => sum + current.prazoRealizado, 0);
                            this.totalPrazoVencido = atvEtapa.responsabilidade.reduce((sum: any, current: any) => sum + current.prazoVencido, 0);
                            this.abrirDetalheEtapa(etapa, atvEtapa.responsabilidade);
                        }
                    }
                },
                (err: any) => {
                    console.log(err);
                }
            );
    }

    voltarTelaAnterior() {
        this.router.navigate(['/demanda']);
    }

    abrirDetalheEtapa(etapa: AtividadeEtapa, responsabilidades?: Responsabilidade[]) {

        if (etapa && etapa.historico) {
            etapa.historico = etapa.historico.sort((a: any, b: any) => new Date(a.dataInicio).getTime() - new Date(b.dataInicio).getTime());

            this.displayDialogEtapas = true;
            this.nomeEtapa = 'DETALHE DA ETAPA ' + etapa.etapa;
            this.dataInicioEtapa = etapa.dataInicio;
            this.dataFimEtapa = etapa.dataFim;
            this.listaDetalheEtapa = etapa.historico.sort((a: any, b: any) => new Date(a.dataInicio).getTime() - new Date(b.dataInicio).getTime());

            this.prazoObjetivoEtapa = etapa.prazoObjetivo;
            this.prazoRealDiligencia = etapa.diasRealizadosDiligencia;
            this.desvioEtapa = etapa.desvio;
            this.etapaCor = etapa.cor;

            if (etapa.prazoReal && etapa.diasRealizadosDiligencia) {
                this.prazoRealEtapa = etapa.prazoReal - etapa.diasRealizadosDiligencia;
            }
        }


    }

    calculateSituacaoTotal(name: any) {
        let total = 0;

        if (this.listaDetalheEtapa) {
            for (let etapa of this.listaDetalheEtapa) {
                if (etapa.situacao === name) {
                    total += <any>etapa.prazoDias;
                }
            }
        }

        return total;
    }

    calculateSituacaoDiligenciaTotal(name: any) {
        let total = 0;

        if (this.listaDetalheEtapa) {
            for (let etapa of this.listaDetalheEtapa) {
                if (etapa.situacao === name) {
                    total += <any>etapa.prazoDiasDiligencia;
                }
            }
        }

        return total;
    }

    closeDetailEtapa() {
        this.displayDialogEtapas = !this.displayDialogEtapas;
    }
}