import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { MensagemErro, UsuarioModel, UsuarioManager } from 'src/app/models/usuario';
import { Retorno } from 'src/app/models/retorno';
import { ComboBase } from 'src/app/models/comboModel';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  private environmentUrl: string = '';

  dialogUsuario = new EventEmitter();
  usuarioSecretariaHierarquiaEmitter = new EventEmitter();


  constructor(private httpClient: HttpClient) {
    this.environmentUrl = environment.ApiCore + "v1/Usuario/";
  }

  findUsuarioById(idUsuario: number): Observable<UsuarioModel> {
    let url = this.environmentUrl + "GetUsuario/" + idUsuario;
    return this.httpClient.get<UsuarioModel>(url);
  }

  // getUsuarios(text: string, pagina: number, totPag: number) : Observable<UsuarioModel[]> {
  //   if (text=='') {
  //     text = 'null';
  //   } else {
  //     text = text.replace('\\', '').replace('/', '');
  //   }      
  //   let url = this.environmentUrl + "GetUsuarios/" + text + "/" + pagina + "/" + totPag;
  //   return this.httpClient.get<UsuarioModel[]>(url);
  // }
  getUsuarios(): Observable<UsuarioModel[]> {

    let url = this.environmentUrl + "GetList";
    return this.httpClient.get<UsuarioModel[]>(url);
  }

  setUsuario(usuarioModel: UsuarioModel) {

    let params = {
      IdUsuario: usuarioModel.idUsuario,
      Login: usuarioModel.login,
      Senha: usuarioModel.senha,
      ConfirmacaoSenha: usuarioModel.confirmacaoSenha,
      Nome: usuarioModel.nome,
      Telefone: usuarioModel.telefone != null && usuarioModel.telefone != '' && usuarioModel.telefone != undefined ? usuarioModel.telefone.toString().replace("(", "").replace(")", "").replace("-", "").replace(" ", "") : "",
      NomeEmpresa: usuarioModel.nomeEmpresa,
      UltimoAcesso: usuarioModel.ultimoAcesso,
      Email: usuarioModel.email,
      Perfil: usuarioModel.perfil,
      Empresa: usuarioModel.empresa,
      EmpresaTerceira: usuarioModel.empresaTerceira,
      IdEmpresaTerceira: usuarioModel.idEmpresaTerceira,
      IdHierarquia: usuarioModel.idHierarquia,
      NomeHierarquia: usuarioModel.nomeHierarquia,
      IdFuncionario: usuarioModel.idFuncionario,
      NomeFuncionario: usuarioModel.nomeFuncionario,
      PerfisConcatenados: usuarioModel.perfisConcatenados,
      NomePerfil: usuarioModel.nomePerfil,
      FlagBloqueado: usuarioModel.flagBloqueado,
      FlagUsuarioProprio: usuarioModel.flagUsuarioProprio,
      ListaPerfil: usuarioModel.listaPerfil,
      ListaEmpresa: usuarioModel.listaEmpresa,
      IdsHierarquia: usuarioModel.IdsHierarquia,
      ListaHierarquia: usuarioModel.listaHierarquia
    };

    let url = this.environmentUrl + "SetItem";
    return this.httpClient.post<MensagemErro>(url, params);
  }

  setUsuarioLogado(usuarioModel: UsuarioManager) {
    let params = {
      IdUsuario: usuarioModel.idUsuario,

      Senha: usuarioModel.senha,
      ConfirmacaoSenha: usuarioModel.confirmacaoSenha,

      Telefone: usuarioModel.telefone != null && usuarioModel.telefone != '' && usuarioModel.telefone != undefined ? usuarioModel.telefone.toString().replace("(", "").replace(")", "").replace("-", "").replace(" ", "").replace("+", ""): "",
      IdsHierarquia: usuarioModel.IdsHierarquia,
      IdHierarquia: usuarioModel.idHierarquia,
      NomeHierarquia: usuarioModel.nomeHierarquia,
      SenhaAtual: usuarioModel.senhaAtual
    };

    let url = this.environmentUrl + "SetItemLogado";
    return this.httpClient.post<MensagemErro>(url, params);
  }

  delUsuario(idUsuario: number) {

    let url = this.environmentUrl + "DelItem/" + idUsuario;
    return this.httpClient.delete<boolean>(url);
  }

  desbloquearUsuario(idUsuario: number): Observable<Retorno> {
    let params = {
      IdUsuario: idUsuario
    };
    let url = this.environmentUrl + "SetUsuarioDesbloquear";
    return this.httpClient.post<Retorno>(url, params);
  }

  getUsuarioSecretariaCombo() {
    let url = this.environmentUrl + "secretaria/combo";
    return this.httpClient.get<ComboBase[]>(url);
  }

  getUsuarioSecretariaHierarquiaCombo(idHierarquia: number) {
    let url = this.environmentUrl + "secretaria/hierarquia/combo/" + idHierarquia;
    return this.httpClient.get<ComboBase[]>(url);
  }

  redefineSenha(idUsuario: number): Observable<boolean> {
    let url = this.environmentUrl + "RedefineSenha?idUsuario=" + idUsuario;
    return this.httpClient.patch<boolean>(url, null);
  }
}