import { AuthenticationGuard } from './core/guards/authentication.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessdeniedComponent } from './shared/components/accessdenied/accessdenied.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./features/login/login.module').then(
        (module) => module.LoginModule,
      ),
  },
  {
    canActivate: [AuthenticationGuard],
    path: 'home',
    loadChildren: () =>
      import('./features/home/home.module').then((module) => module.HomeModule),
  },
  {
    canActivate: [AuthenticationGuard],
    path: 'etapa',
    loadChildren: () =>
      import('./features/stage/stage.module').then(
        (module) => module.StageModule,
      ),
  },
  {
    canActivate: [AuthenticationGuard],
    path: 'tratamento-prazos',
    loadChildren: () =>
      import('./features/alarm-handling/alarm-handling.module').then(
        (module) => module.AlarmHandlingModule,
      ),
  },
  {
    canActivate: [AuthenticationGuard],
    path: 'tratamento-diligencias',
    loadChildren: () =>
      import('./features/diligence-handling/diligence-handling.module').then(
        (module) => module.DiligenceHandlingModule,
      ),
  },
  {
    canActivate: [AuthenticationGuard],
    path: 'demandas',
    loadChildren: () =>
      import('./features/demands/demands.module').then(
        (module) => module.DemandsModule,
      ),
  },
  {
    canActivate: [AuthenticationGuard],
    path: 'relatorio-quantitativo',
    loadChildren: () =>
      import('./features/quantitative/quantitative.module').then(
        (module) => module.QuantitativeModule,
      ),
  },
  {
    canActivate: [AuthenticationGuard],
    path: 'checklist',
    loadChildren: () =>
      import('./features/checklist/checklist.module').then(
        (module) => module.ChecklistModule,
      ),
  },
  {
    canActivate: [AuthenticationGuard],
    path: 'transition-flow',
    loadChildren: () =>
      import('./features/transition-flow/transition-flow.module').then(
        (module) => module.TransitionFlowModule,
      ),
  },
  {
    path: 'agendamento',
    loadChildren: () =>
      import('./features/scheduling/scheduling.module').then(
        (module) => module.SchedulingModule
      ),
  },
  {
    path: 'vistoria',
    loadChildren: () =>
      import('./features/service-order/service-order.module').then(
        (module) => module.ServiceOrderModule
      ),
  },
  {    
    path: 'firstacess',
    loadChildren: () =>
      import('./features/access-control/first-access/first-access.module').then(
        (module) => module.FirstAccessModule,
      ),
  },
  { path: 'accessdenied', component: AccessdeniedComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
