import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { MxlAtividadeFiltro, MxlAtividadeFiltroModel } from 'src/app/models/filtro.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FiltroService {
    private environmentUrl: string = '';
    dialogFiltro = new EventEmitter();
    setQueryStringFiltroEmitter = new EventEmitter();
    setFiltrosEmitter = new EventEmitter();

    constructor(private httpClient: HttpClient,
        private userService: UserService) {
        this.environmentUrl = environment.ApiCore + "v1/Atividade/Filtro/";
    }

    getLista(origem: string): Observable<MxlAtividadeFiltro[]> {
        let url = this.environmentUrl + "GetLista/" + origem;
        return this.httpClient.get<MxlAtividadeFiltro[]>(url);
    }

    findById(idAtividadeFiltro: number): Observable<MxlAtividadeFiltroModel> {
        let url = this.environmentUrl + "GetItem/" + idAtividadeFiltro;
        return this.httpClient.get<MxlAtividadeFiltroModel>(url);
    }

    setItem(filtro: MxlAtividadeFiltroModel) {

        let params = {
            mxlAtividadeFiltro: filtro.mxlAtividadeFiltro,
            mxlAtividadeFiltroMxlAtividade: filtro.mxlAtividadeFiltroMxlAtividade
        };

        let url = this.environmentUrl + "Salvar";
        return this.httpClient.post<number>(url, params);
    }

    delItem(idAtividadeFiltro: number) {
        let url = this.environmentUrl + "DelItem/" + idAtividadeFiltro;
        return this.httpClient.delete<boolean>(url);
    }
}