import { filter } from 'rxjs/operators';
import { OnInit, Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MessageService, TreeNode } from 'primeng/api';
import { HierarquiaService } from 'src/app/core/services/hierarquia/hierarquia.service';
import { ComboBase } from 'src/app/models/comboModel';

@Component({
  selector: 'max-hierarquia-combo',
  templateUrl: './hierarquia-combo.component.html',
  //styleUrls: ['./hierarquia-combo.component.scss']
})

export class HierarquiaComboComponent implements OnInit {

  public lista!: any[];
  public nodes1!: any[];
  public selectedItem!: any;
  public selectedNode!: any;

  @ViewChild('tree') tree: any;

  @Input() idItemRaiz: number = 0;
  @Input() idItem!: number;
  @Input() selectCollpase: boolean = false;
  @Output() itemSelecionado = new EventEmitter();

  constructor(private hierarquiaService: HierarquiaService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.getLista();
  }

  getLista() {

    this.hierarquiaService.getListaCombo(this.idItemRaiz).subscribe(
      {
        next: (res: any) => {
          if (res) {
            this.lista = res;
            if (this.lista !== null) {
              if (this.idItem != null)
                this.selectedItem = <any>this.getNodeWithKey(this.idItem.toString(), this.lista);
            }
          }
        },
        error: (err: any) => {
          console.log(`hierarquiaCombo - getLista : ${err}.`);
        }
      });
  }


  getNodeWithKey(key: string, nodes: TreeNode[]): TreeNode | undefined {

    for (let node of nodes) {
      if (node.key === key) {
        return node;
      }

      if (node.children) {
        let matchedNode = this.getNodeWithKey(key, node.children);
        if (matchedNode) {
          return matchedNode;
        }
      }
    }
    return undefined;
  }


  nodeSelect(event: any) {

    //console.log(event) ;
    this.selectedItem = event.node;
    this.itemSelecionado.emit(this.selectedItem);
    if (this.selectCollpase == true) {
      //this.tree.filter = false;
      this.tree.filteredNodes = null;
      //console.log(this.tree);
      this.collapseAll();
      //this.tree.filter = true;
    }

  }

  nodeUnselect(event: any) {
    //console.log(event.node);
  }

  collapseAll() {
    this.lista.forEach(node => {
      this.expandRecursive(node, false);
    });
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

}
