import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { MessageService, TreeNode } from 'primeng/api';
import { HierarquiaComboService } from 'src/app/core/services/hierarquia-combo.service';
import { ComboBase } from 'src/app/models/comboModel';


@Component({
  selector: 'max-hierarquia-combo-check',
  templateUrl: './hierarquia-combo-check.component.html',
  styleUrls: ['./hierarquia-combo-check.component.scss']
})

export class HierarquiaComboCheckComponent implements OnInit {

  public list!: any;
  public selectedList!: any;
  public item!: any;


  @Input() idItemRaiz: number = 0;
  @Input() selectedItem!: any;
  @Input() removedItem!: ComboBase[];
  @Input() flagHierarquiaFull: boolean = false;
  @Output() itemSelecionado = new EventEmitter();

  constructor(private hierarquiaService: HierarquiaComboService, private messageService: MessageService) { }

  ngOnInit(): void {

    if (this.flagHierarquiaFull) {
      this.getListaFull();
    } else {
      this.getLista();
    }
  }

  getLista() {
    this.hierarquiaService.getListaCombo(this.idItemRaiz).subscribe(
      {
        next: (res) => {

          if (res)
            this.list = res;
          if (this.list != null) {
            // this.setRemoved(this.lista);           
            this.setSelected();
          }

          console.log('getLista ', this.list);

        },
        error: (err) => {
          console.log(`hierarquiaComboCheck - getLista : ${err}.`);
        }
      });
  }

  getListaFull() {
    this.hierarquiaService.getListaComboFull().subscribe(
      {
        next: (res: any) => {
          if (res)
            this.list = res;
          if (this.list != null) {
            this.setSelected();
          }

          console.log('getListaFull ', this.list);
        },
        error: (err: any) => {
          console.log(`hierarquiaComboCheck - getListaFull : ${err}.`);
        }
      });
  }

  nodeSelect(event: any) {
    this.itemSelecionado.emit(this.selectedList)
  }

  nodeUnselect(event: any) {
    this.itemSelecionado.emit(this.selectedList)
  }

  setSelected() {
    if (this.selectedItem != null && this.selectedItem !== undefined) {
      var self: any = this;
      var arr = [];

      for (var i = 0, len = this.selectedItem.length; i < len; i++) {
        self.item = self.getNodeWithKey(self.selectedItem[i].value, self.list);

        if (self.item !== undefined) {
          arr.push(self.item);
        }

      }
      this.selectedList = arr;
    }
  }

  setRemoved(lista: TreeNode[]) {
  }

  getNodeWithKey(key: string, nodes: TreeNode[]): TreeNode | undefined {

    for (let node of nodes) {
      if (node.key === key) {
        return node;
      }

      if (node.children) {
        let matchedNode = this.getNodeWithKey(key, node.children);
        if (matchedNode) {
          return matchedNode;
        }
      }
    }
    return undefined;
  }

  delNodeWithKey(key: string, nodes: TreeNode[]): TreeNode | undefined {


    for (let node of nodes) {
      if (node.key === key) {
        return node;
      }

      if (node.children) {

        var i;
        for (i = 0; i < node.children.length; i++) {
          if (node.children[i].key == key) {
            node.children.splice(i, 1);
            return undefined;
          }
        }


        let matchedNode = this.delNodeWithKey(key, node.children);
        if (matchedNode) {
          return matchedNode;
        }
      }
    }
    return undefined;
  }

  deleteNode(topNode: TreeNode, selectedNode: TreeNode) {
    if (topNode.children != null) {
      var i;

      for (i = 0; i < topNode.children.length; i++) {
        if (topNode.children[i].data.id == selectedNode.data.id) {
          topNode.children.splice(i, 1);
          return;
        }
        else this.deleteNode(topNode.children[i], selectedNode);
      }
    }
    else return;
  }
}

