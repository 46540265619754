<div class="card">
    <button pButton type="button" icon="pi pi-plus" iconPos="left" (click)="inserirAnexo()"
        style="width: 130px; margin: 3px;" class="p-button-info" label="Inserir"></button>
</div>
<div *ngIf="(showForm)">
    <form [formGroup]="anexoAddForm" (submit)="saveAnexo()">

        <div class="card">
            <p-fileUpload #fileUpload [showUploadButton]="false" chooseLabel="Adicionar" cancelLabel="Cancelar"
                id="atividadeAnexo" name="atividadeAnexo[]" [customUpload]="true" (uploadHandler)="onUpload($event)"
                [multiple]="true"
                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/*,application/pdf"
                [maxFileSize]="1000000" [showCancelButton]="false">
                <ng-template pTemplate="content">
                    <ul *ngIf="uploadedFiles.length">
                        <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                    </ul>
                </ng-template>
            </p-fileUpload>
            <b>Extensões válidas:</b> .jpg, .jpeg, .png, .pdf, .xls e .xlsx
        </div>

        <div class="card">

            <div class="formgroup-inline">
                <div class="col-6">
                    <label for="nomeArquivo">Nome:</label>
                    <input formControlName="nomeArquivo" id="nomeArquivo" style="width: 100%;" type="text"
                        aria-describedby="nomeArquivo-help" pInputText />
                    <small class="text-red-500" *ngIf="anexoAddForm.get('nomeArquivo')?.errors?.['required']">Nome do
                        Arquivo
                        é obrigatório.</small>
                    <small class="text-red-500" *ngIf="anexoAddForm.get('nomeArquivo')?.errors?.['maxlength']">Máximo de
                        caracteres é 100.</small>
                </div>
                <div class="col-6">
                    <label for="descricao">Descrição:</label>
                    <input formControlName="descricao" id="descricao" style="width: 100%;" type="text"
                        aria-describedby="descricao-help" pInputText />
                    <small class="text-red-500" *ngIf="anexoAddForm.get('descricao')?.errors?.['required']">Descrição do
                        Arquivo é obrigatório.</small>
                    <small class="text-red-500" *ngIf="anexoAddForm.get('descricao')?.errors?.['maxlength']">Máximo de
                        caracteres é 250.</small>
                </div>
            </div>
            <div class="formgroup-inline">
                <div class="col-12">
                    <label for="observacao">Observação:</label>
                    <input formControlName="observacao" id="observacao" style="width: 100%;" type="text"
                        aria-describedby="observacao-help" pInputText />
                    <small class="text-red-500" *ngIf="anexoAddForm.get('observacao')?.errors?.['maxlength']">Máximo de
                        caracteres é 500.</small>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="formgroup-inline">
                <button pButton pRipple label="Limpar Campos" icon="pi pi-trash" style="width: 180px; margin: 3px;"
                    class="p-button-danger mr-2" (click)="resetAnexoAddForm()"></button>
                <button pButton pRipple label="Cancelar" icon="pi pi-times" (click)="inserirAnexo()"
                    style="width: 130px; margin: 3px;" class="p-button-info mr-2"></button>
                <button type="submit" [disabled]="anexoAddForm.invalid" pButton pRipple label="Confirmar"
                    icon="pi pi-check" style="width: 130px; margin: 3px;" class="p-button-success mr-2"></button>
            </div>
        </div>
    </form>
</div>

<div *ngIf="(!showForm)">

    <div class="card">
        <p-table styleClass="p-datatable-gridlines" [value]="anexos" [scrollable]="true" scrollHeight="300px">
            <ng-template pTemplate="header">
                <tr class="font-grid-atividade-header">
                    <th>Nome</th>
                    <th>Descrição</th>
                    <th>Data</th>
                    <th>Observação</th>
                    <th class="p-text-center">Download</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-anexo>
                <tr class="font-grid-atividade">
                    <td class="break-word">{{anexo.nomeArquivo}}</td>
                    <td class="break-word">{{anexo.descricao}}</td>
                    <td>{{anexo.data | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                    <td class="break-word">{{anexo.observacao}}</td>
                    <td class="p-text-center">
                        <a target="_blank" href="javascript:void(0)"
                            (click)="downloadAnexo(anexo.caminhoArquivo, anexo.nomeArquivo)" download>
                            <span><i class="pi pi-download"></i></span>
                        </a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>