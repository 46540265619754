import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { AnexosService } from 'src/app/core/services/anexos.service';
import { Anexo } from 'src/app/models/anexo';

@Component({
    selector: 'sgc-anexos',
    templateUrl: './anexos.component.html',
    styleUrls: ['anexos.component.scss']
})
export class AnexosComponent implements OnInit {
    @Input() idAnexoTipo!: number;
    @Input() idEntidade!: number;
    anexos!: Anexo[];

    showForm = false;
    anexoAddForm!: FormGroup;
    uploadedFiles: File[] = [];

    @ViewChild('fileUpload') fileUpload!: FileUpload;


    constructor(
        private formBuilder: FormBuilder,
        private anexoService: AnexosService,
        private messageService: MessageService
    ) { }

    ngOnInit(): void {
        this.getAnexos();

        this.anexoAddForm = this.formBuilder.group({
            nomeArquivo: ['', [Validators.required, Validators.maxLength(100)]],
            descricao: ['', [Validators.required, Validators.maxLength(250)]],
            observacao: ['', Validators.maxLength(500)]
        });
    }

    getAnexos() {
        this.anexoService.getAnexos(this.idAnexoTipo, this.idEntidade)
            .subscribe(
                (anexos: any) => {
                    this.anexos = anexos;
                }
            );
    }

    inserirAnexo() {
        this.showForm = !this.showForm;
    }

    saveAnexo() {
        const files = this.fileUpload.files;

        if (files.length == 0) {
            this.messageService.add({ severity: 'error', summary: 'Por favor, selecione o arquivo.', detail: '' });
        }

        else {
            const nomeArquivo = this.anexoAddForm.get('nomeArquivo')?.value;
            const descricao = this.anexoAddForm.get('descricao')?.value;
            const observacao = this.anexoAddForm.get('observacao')?.value;

            for (let file of files) {
                const extensao = file.name.toString().split('.')[1];
                if ((extensao != "jpg") && (extensao != "jpeg") && (extensao != "png") && (extensao != "xls") && (extensao != "xlsx") && (extensao != "pdf")) {
                    this.messageService.add({ severity: 'error', summary: 'Insira somente arquivos com extensões: .jpg, .jpeg, .png, .pdf, .xls ou .xlsx' + file.name, detail: '' });
                    return false;
                }
                if (file.size > 4194304) {
                    this.messageService.add({ severity: 'error', summary: 'O arquivo excedeu o tamanho máximo suportado.' + file.name, detail: '' });
                } else {
                    this.uploadedFiles.push(file);
                }
            }

            if (this.uploadedFiles.length > 0) {
                this.anexoService.upload(this.uploadedFiles, this.idAnexoTipo, this.idEntidade, nomeArquivo, descricao, observacao)
                    .subscribe((result: any) => {
                        if (result.length > 0 && result[0].sucesso == true) {
                            this.messageService.add({ severity: 'success', summary: result[0].mensagem, detail: '' });

                            this.anexoService.anexoEmitter.emit(
                                [
                                    { idAnexoTipo: this.idAnexoTipo },
                                    { idEntidade: this.idEntidade }
                                ]
                            );

                            this.getAnexos();
                            this.inserirAnexo();
                            this.resetAnexoAddForm();
                        } else if (result.length > 0 && result[0].sucesso == false) {
                            this.messageService.add({ severity: 'error', summary: result[0].mensagem, detail: '' });
                        }
                    },
                        (err: any) => {
                            this.messageService.add({ severity: 'error', summary: 'Erro ao inserir o(s) arquivo(s).', detail: '' });
                        });
            } else {
                return false;
            }
        }
        return false;
    }

    resetAnexoAddForm() {
        this.fileUpload.clear();
        this.anexoAddForm.reset();
        this.anexoAddForm.markAsPristine();
        this.anexoAddForm.markAsUntouched();
        this.uploadedFiles = [];
        return false;
    }

    onUpload(event: any) {

        for (let file of event.files) {
            this.anexoAddForm.patchValue({ myFile: file });
            this.anexoAddForm.get('myFile')?.updateValueAndValidity();
        }
    }

    downloadAnexo(url: string, nomeDoArquivo: string) {

        if (nomeDoArquivo.indexOf('.pdf') > -1) {
            const fileURL = url + '?sv=2022-11-02&ss=bf&srt=o&sp=rltf&se=2031-01-01T02:59:59Z&st=2024-03-11T17:32:35Z&spr=https&sig=78Tghp1LzdfmywUOitLMVsr1bEAWp%2FFxkL%2BfUQDbbvE%3D';
            window.open(fileURL, '_blank', 'width=1000, height=800');
        } else {
            const downloadLink = document.createElement('a');
            downloadLink.href = url + '?sv=2022-11-02&ss=bf&srt=o&sp=rltf&se=2031-01-01T02:59:59Z&st=2024-03-11T17:32:35Z&spr=https&sig=78Tghp1LzdfmywUOitLMVsr1bEAWp%2FFxkL%2BfUQDbbvE%3D';
            downloadLink.download = nomeDoArquivo;
            downloadLink.click();
            downloadLink.remove();
        }
    }
}