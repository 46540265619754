import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';
import { Retorno } from 'src/app/models/retorno';
import { AtividadeRelacao } from '../models/atividade/atividade-relacao';
import { AcionamentoTipo } from '../models/atividade/acionamento-tipo';
import { AtividadeClasse } from '../models/atividade/atividade-classe';
import { AtividadeClassificacao } from '../models/atividade/atividade-classificacao';
import { AtividadeDetalhe } from '../models/atividade/atividade-detalhe';
import { AtividadeTipoDados } from '../models/atividade/atividade-tipo-dados';
import { AtividadeFluxoTransicaoPorStatus } from '../models/atividade/atividade-fluxo-transicao-por-status';
import { AtividadeEtapaDetalhe } from '../models/atividade/atividade-etapa';

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName?: string) => boolean;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AtividadeService {
  private environmentUrl: string = '';
  atividadeSearch = new EventEmitter();
  atividadeEvents = new EventEmitter();
  demandaOnLoad = new EventEmitter();
  demandaGanttOnLoad = new EventEmitter();

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
  ) {
    this.environmentUrl = environment.ApiAtv;
  }

  getRelacaoAtividade(
    idHierarquia: number,
    matriculaTecnico: string,
    numeroBa: string,
    numeroBaOriginal: string,
    equipeResponsavel: string,
    idAtividadeStatus: number,
    idAcionamentoTipo: string,
    idAtividadeTipo: number[],
    idAcionamento: string,
    idProvisorio: string,
    dataEncerrado: Date,
    numeroDocumento: string,
    pagina: number,
    totPag: number,
  ): Observable<AtividadeRelacao[]> {
    let params = {
      IdAtividade: null,
      IdEmpresa: this.userService.getIdEmpresa(),
      IdUsuario: this.userService.getUserId(),
      IdHierarquia:
        idHierarquia === undefined || idHierarquia == null
          ? null
          : idHierarquia,
      DataStatusOs: null,
      MatriculaTecnico: matriculaTecnico,
      NumeroBa: numeroBa == '' ? null : numeroBa,
      NumeroBaOriginal: numeroBaOriginal == '' ? null : numeroBaOriginal,
      CodTipoAtividade: null,
      IdAtividadeStatus: idAtividadeStatus,
      IdAtividadeTipo:
        idAtividadeTipo == null ? null : idAtividadeTipo.join(','),
      IdAtividadeClassificacao: null,
      Descricao: null,
      EquipeResponsavel: equipeResponsavel == '' ? null : equipeResponsavel,
      MatriculaImovel: null,
      TipoAtendimento: null,
      Endereco: null,
      IdentificadorCliente: null,
      DataEncerramento: dataEncerrado,
      IdAcionamentoTipo: idAcionamentoTipo,
      ID: idAcionamento,
      IdProvisorio: idProvisorio,
      NumeroDocumento: numeroDocumento,
      Pagina: pagina,
      TotPag: totPag,
    };

    console.log(params);

    let url = this.environmentUrl + '/Atividade/GetRelacaoAtividade';
    return this.httpClient.post<AtividadeRelacao[]>(url, params);
  }

  // addAtividade(atividadeDetalhe: AtividadeDetalhe): Observable<Retorno> {

  //     let params = {
  //         IdEmpresa: this.userService.getIdEmpresa(),
  //          //Identificação
  //         IdTipoAcionamento: atividadeDetalhe.idAcionamentoTipo,
  //         ID: atividadeDetalhe.idAcionamento,
  //         IdProvisorio: atividadeDetalhe.idProvisorio,
  //         IdAtividadeStatus: atividadeDetalhe.idStatus,
  //         NumeroBa: atividadeDetalhe.numeroBa,
  //         NumeroBaOriginal: atividadeDetalhe.numeroBaOriginal,
  //         NumeroDocumento: atividadeDetalhe.numeroDocumento,
  //         Solicitante: atividadeDetalhe.solicitante,
  //         //Serviço
  //         IdProduto: atividadeDetalhe.idProduto,
  //         IdCaracteristica: atividadeDetalhe.idCaracteristica,
  //         IdAtividadeTipo: atividadeDetalhe.idTipoAtividade,
  //         IdClassificacao: atividadeDetalhe.idAtividadeClassificacao,
  //         TempoExecucaoPrevisto: atividadeDetalhe.tempoExecucaoPrevisto,
  //         FatorEquivalente: atividadeDetalhe.fatorEquivalente,
  //         //Cliente
  //         IdCliente: atividadeDetalhe.idCliente,
  //         Site: atividadeDetalhe.site,
  //         Descricao: atividadeDetalhe.descricao,
  //         Observacao: atividadeDetalhe.observacao,
  //         //Localização
  //         IdHierarquia: atividadeDetalhe.idHierarquia,
  //         Endereco: atividadeDetalhe.endereco,
  //         Latitude: atividadeDetalhe.latitude,
  //         Longitude: atividadeDetalhe.longitude,
  //         //Acompanhamento
  //         DataInicioAgendamento: atividadeDetalhe.dataInicioAgendamento,
  //         DataFimAgendamento: atividadeDetalhe.dataFimAgendamento,
  //         DataLimiteAtendimento: atividadeDetalhe.limiteAtendimento
  //     };

  //     let url = this.environmentUrl + "/Atividade/InsercaoAtividade";
  //     return this.httpClient.post<Retorno>(url, params);
  // }

  // updAtividade(atividadeDetalhe: AtividadeDetalhe) {
  //     try {

  //         let params = {
  //             IdAtividade: atividadeDetalhe.idAtividade,
  //             IdEmpresa: this.userService.getIdEmpresa(),
  //              //Identificação
  //             IdTipoAcionamento: atividadeDetalhe.idAcionamentoTipo,
  //             ID: atividadeDetalhe.idAcionamento,
  //             IdProvisorio: atividadeDetalhe.idProvisorio,
  //             IdAtividadeStatus: atividadeDetalhe.idStatus,
  //             NumeroBa: atividadeDetalhe.numeroBa,
  //             NumeroBaOriginal: atividadeDetalhe.numeroBaOriginal,
  //             NumeroDocumento: atividadeDetalhe.numeroDocumento,
  //             Solicitante: atividadeDetalhe.solicitante,
  //             //Serviço
  //             IdProduto: atividadeDetalhe.idProduto,
  //             IdCaracteristica: atividadeDetalhe.idCaracteristica,
  //             IdAtividadeTipo: atividadeDetalhe.idTipoAtividade,
  //             IdClassificacao: atividadeDetalhe.idAtividadeClassificacao,
  //             TempoExecucaoPrevisto: atividadeDetalhe.tempoExecucaoPrevisto,
  //             FatorEquivalente: atividadeDetalhe.fatorEquivalente,
  //             //Cliente
  //             IdCliente: atividadeDetalhe.idCliente,
  //             Site: atividadeDetalhe.site,
  //             Descricao: atividadeDetalhe.descricao,
  //             Observacao: atividadeDetalhe.observacao,
  //             //Localização
  //             IdHierarquia: atividadeDetalhe.idHierarquia,
  //             Endereco: atividadeDetalhe.endereco,
  //             Latitude: atividadeDetalhe.latitude,
  //             Longitude: atividadeDetalhe.longitude,
  //             //Acompanhamento
  //             DataInicioAgendamento: atividadeDetalhe.dataInicioAgendamento,
  //             DataFimAgendamento: atividadeDetalhe.dataFimAgendamento,
  //             DataLimiteAtendimento: atividadeDetalhe.limiteAtendimento
  //         };

  //         let url = this.environmentUrl + "/Atividade/UpdateAtividade";
  //         return this.httpClient.post(url, params);
  //     }
  //     catch(ex) {
  //         console.log(ex);
  //         return null;
  //     }
  // }

  getAcionamentoTipo(): Observable<AcionamentoTipo[]> {
    let url = this.environmentUrl + '/WfmAcionamentoTipo/ListItem';
    return this.httpClient.get<AcionamentoTipo[]>(url);
  }

  getAtividadeClasse(): Observable<AtividadeClasse[]> {
    let url = this.environmentUrl + '/WfmAtividadeClasse/ListItem';
    return this.httpClient.get<AtividadeClasse[]>(url);
  }

  getAtividadeClassificacao(): Observable<AtividadeClassificacao[]> {
    let url = this.environmentUrl + '/WfmAtividadeClassificacao/GetCombo';
    return this.httpClient.get<AtividadeClassificacao[]>(url);
  }

  findAtividadeById(idAtividade: number) {
    const idUsuario = this.userService.getUserId();
    const idEmpresa = this.userService.getIdEmpresa();

    let url = this.environmentUrl + 'v1/Atividade/Get/' + idAtividade;
    return this.httpClient.get<AtividadeDetalhe>(url);
  }

  getAtividadeDadosTipoDeServico(idAtividadeTipo: number) {
    let url =
      this.environmentUrl +
      '/Atividade/GetAtividadeDadosTipoServico/' +
      idAtividadeTipo;
    return this.httpClient.get<AtividadeTipoDados>(url);
  }

  getFluxoTransicaoPorStatus(
    idAtividadeStatus: number,
    idAtividadeTipo: number,
  ): Observable<AtividadeFluxoTransicaoPorStatus[]> {
    let url =
      this.environmentUrl +
      '/AtividadeFluxo/Transicao/GetFluxoTransicaoPorStatus/' +
      idAtividadeStatus +
      '/' +
      idAtividadeTipo;
    return this.httpClient.get<AtividadeFluxoTransicaoPorStatus[]>(url);
  }

  updAtividadeStatus(
    idAtividade: number,
    matriculaResponsavel: string,
    idAtividadeStatusAnterior: number,
    idAtividadeStatusProx: number,
  ): Observable<Retorno> {
    try {
      let dataAtual = new Date();
      let params = {
        MatriculaResponsavel: matriculaResponsavel,
        IdAtividade: idAtividade,
        IdStatusAnterior: idAtividadeStatusAnterior,
        IdStatusAtual: idAtividadeStatusProx,
        Data: new Date(),
        Latitude: '',
        Longitude: '',
      };

      let url = this.environmentUrl + '/Atividade/UpdateAtividadeStatus';
      return this.httpClient.post<Retorno>(url, params);
    } catch (ex) {
      console.log(ex);
      return throwError(() => new Error());
    }
  }

  getAtividadeEtapa(idAtividade: number): Observable<AtividadeEtapaDetalhe> {
    let url =
      this.environmentUrl + 'v1/Atividade/etapaplanejada/' + idAtividade;
    return this.httpClient.get<AtividadeEtapaDetalhe>(url);
  }
}
