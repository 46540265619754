<ng-container *ngIf="onValidadeUser(); then loggedIn; else loggedOut"></ng-container>

<ng-template #loggedIn>
  <section class="c-main">

    <div class="c-main__header">
      <sgc-header></sgc-header>
    </div>

    <div class="c-main__container">
      <div class="c-main__container--menu">
        <sgc-menu></sgc-menu>
      </div>

      <div class="c-main__container--content">
        <router-outlet></router-outlet>
      </div>
    </div>

    <div class="c-main__footer">
      <sgc-footer></sgc-footer>
    </div>

  </section>
</ng-template>

<ng-template #loggedOut>
  <router-outlet></router-outlet>
</ng-template>