import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {
  private contador = 0;

  private loaderSubject = new Subject<{ show: boolean }>();

  loaderState = this.loaderSubject.asObservable();

  constructor() {}

  show() {
    this.contador++;
    this.loaderSubject.next(<{ show: boolean }>{ show: true });
  }

  hide() {
    this.contador--;
    if (this.contador <= 0) {
      this.contador = 0;
      this.loaderSubject.next(<{ show: boolean }>{ show: false });
    }
  }
}
