import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AtividadeTipoService } from 'src/app/core/services/atividade-tipo.service';
import { ComboBase } from 'src/app/models/comboModel';

@Component({
    selector: 'sgc-tipo-servico-multiselect',
    templateUrl: './atividade-tipo-multiselect.component.html'
})
export class AtividadeTipoMultiselectComponent implements OnInit {

    list: ComboBase[] = [];
    selectedList: ComboBase[] = [];
    @Input() selectedItem: ComboBase[] = [];
    @Output() itemSelecionado = new EventEmitter();

    ngOnInit(): void {
        this.getTipoServico();
    }

    constructor(private atividadeTipoService: AtividadeTipoService) {}    

    getTipoServico() {
        this.atividadeTipoService.getListaCombo()
            .subscribe(
                (res) => {
                    if (res) {
                        // for (let i = 0; i < res.length; i++) {
                        //     res[i].text = res[i].code + ' - ' + res[i].text;
                        //     if (res[i].text.length > 80) {
                        //       res[i].text = res[i].text.substring(0, 80) + '...';
                        //     }
                        // }
                        this.list = res;
                        this.selectedList = this.selectedItem;
                    }
                },
                err => {
                    console.log(`AtividadeTipoMultiSelectList ` + err);
                }
            );
    }   
    
    onChange(event: any) {
        if (event.value !== null && event.value !== undefined) {   
            this.itemSelecionado.emit(event.value);
        }
    }
}