import { EventInput } from '@fullcalendar/core';

let eventGuid = 0;
const TODAY_STR = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

export const INITIAL_EVENTS: EventInput[] = [
  {
    id: createEventId(),
    resourceId: '0',
    title: '',
    start: '2023-01-01' + 'T00:00:00',
    end: '2023-01-02' + 'T12:00:00',
    className: 'item_event expected',
    color: 'rgb(250, 192, 144)',
    extendedProps: {
        description: '',
    }
  },
  {
    resourceId: '1',
    title: '',
    start: '2023-01-02' + 'T12:00:00',
    end: '2023-01-04' + 'T06:00:00',
    className: 'item_event expected',
    color: 'rgb(255, 192, 0)',
    extendedProps: {
        description: '',
    }
  },
  {
    resourceId: '2',
    title: '',
    start: '2023-01-04' + 'T06:00:00',
    end: '2023-01-05' + 'T12:00:00',
    className: 'item_event expected',
    color: 'rgb(151, 112, 60)',
    extendedProps: {
        description: '',
    }
  },
  {
    resourceId: '1',
    title: '',
    start: '2023-01-05' + 'T12:00:00',
    end: '2023-01-07' + 'T06:00:00',
    className: 'item_event expected',
    color: 'rgb(255, 192, 0)',
    extendedProps: {
        description: '',
    }
  },
  {
    resourceId: '3',
    title: '',
    start: '2023-01-07' + 'T12:00:00',
    end: '2023-01-09' + 'T12:00:00',
    className: 'item_event expected',
    color: '#FF0000',
    extendedProps: {
        description: '',
    }
  },
  {
    resourceId: '4',
    title: '',
    start: '2023-01-09' + 'T12:00:00',
    end: '2023-01-11' + 'T12:00:00',
    className: 'item_event expected',
    color: '#1617FE',
    extendedProps: {
        description: '',
    }
  },
  {
    resourceId: '5',
    title: '',
    start: '2023-01-11' + 'T12:00:00',
    end: '2023-01-13' + 'T12:00:00',
    className: 'item_event expected',
    color: '#581D22',
    extendedProps: {
        description: '',
    }
  },
  {
    resourceId: '6',
    title: '',
    start: '2023-01-13' + 'T12:00:00',
    end: '2023-01-15' + 'T12:00:00',
    className: 'item_event expected',
    color: '#02A724',
    extendedProps: {
        description: '',
    }
  },
  {
    resourceId: '7',
    title: '',
    start: '2023-01-15' + 'T12:00:00',
    end: '2023-01-17' + 'T12:00:00',
    className: 'item_event expected',
    color: '#92D050',
    extendedProps: {
        description: '',
    }
  },
  {
    resourceId: '8',
    title: '',
    start: '2023-01-17' + 'T12:00:00',
    end: '2023-01-19' + 'T12:00:00',
    className: 'item_event expected',
    color: '#C00000',
    extendedProps: {
        description: '',
    }
  },
  {
    resourceId: '9',
    title: '',
    start: '2023-01-19' + 'T12:00:00',
    end: '2023-01-21' + 'T12:00:00',
    className: 'item_event expected',
    color: '#660066',
    extendedProps: {
        description: '',
    }
  }
];

export function createEventId() {
  return String(eventGuid++);
}
