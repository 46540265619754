import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ComboBase } from 'src/app/models/comboModel';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ClienteService {

    private environmentUrl: string = '';

    constructor(
        private httpClient: HttpClient
    ) {
        this.environmentUrl = environment.ApiCore;
    }

    getClientesByMatricula(text: string): Observable<ComboBase[]> {

        let url = this.environmentUrl + "v1/Cliente/GetClientesByMatricula/" + text;
        return this.httpClient.get<ComboBase[]>(url);
    }
}