import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TreeNode } from 'primeng/api';
import { ComboBase } from 'src/app/models/comboModel';
import { Hierarquia, HierarquiaModel } from 'src/app/models/hierarquia';

@Injectable({
  providedIn: 'any',
})
export class HierarquiaService {
  private environmentUrl: string = '';

  hierarquiaRegionalEmit = new EventEmitter();
  dialogHierarquia = new EventEmitter();

  constructor(private httpClient: HttpClient) {
    this.environmentUrl = environment.ApiCore + 'v1.0/Hierarquia/';
  }

  getListaCombo(idhierarquiaSelecionada?: number): Observable<TreeNode[]> {
    if (idhierarquiaSelecionada == null) idhierarquiaSelecionada = 0;

    let url = this.environmentUrl + 'GetCombo/' + idhierarquiaSelecionada;
    return this.httpClient.get<TreeNode[]>(url);
  }

  getListaRegionalCombo(): Observable<ComboBase[]> {
    let url = this.environmentUrl + 'GetComboRegional';
    return this.httpClient.get<ComboBase[]>(url);
  }

  getListaPrefeituraCombo(idRegional: number): Observable<ComboBase[]> {
    let url = this.environmentUrl + 'GetComboPrefeitura/' + idRegional;
    return this.httpClient.get<ComboBase[]>(url);
  }

  findHierarquiaById(idHierarquia: number): Observable<Hierarquia> {
    let url = this.environmentUrl + 'GetItemModel/' + idHierarquia;
    return this.httpClient.get<Hierarquia>(url);
  }

  getHierarquias(): Observable<HierarquiaModel[]> {
    let url = this.environmentUrl + 'GetLista';
    return this.httpClient.get<HierarquiaModel[]>(url);
  }

  setHierarquia(hierarquia: HierarquiaModel): Observable<number> {
    let params = {
      idEmpresa: hierarquia.idEmpresa,
      idMunicipio: hierarquia.idMunicipio,
      idHierarquia: hierarquia.idHierarquia,
      idHierarquiaPai: hierarquia.idHierarquiaPai,
      sigla: hierarquia.sigla,
      nome: hierarquia.nome,
      flagAtivo: hierarquia.flagAtivo,
    };

    let url = this.environmentUrl + 'Salvar';
    return this.httpClient.post<number>(url, params);
  }

  delHieraquia(idUsuario: number) {
    let url = this.environmentUrl + 'DelItem/' + idUsuario;
    return this.httpClient.delete<boolean>(url);
  }
}
