import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Anexo } from 'src/app/models/anexo';
import { Retorno } from 'src/app/models/comboModel';
import { MensagemErro } from 'src/app/models/usuario';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AnexosService {

    private environmentUrl: string = '';
    
    anexoEmitter = new EventEmitter();

    constructor(
        private httpClient: HttpClient
    ) {
        this.environmentUrl = environment.ApiAtv + 'v1/';
    }

    getAnexos(idAnexoTipo: number, idEntidade: number) : Observable<Anexo[]> {
        
        let url = this.environmentUrl + 'Anexo/GetList/' + idAnexoTipo + '/' + idEntidade;
        return this.httpClient.get<Anexo[]>(url);
    }

    upload(files: File[], idAnexoTipo: number, idEntidade: number, nomeArquivo: string, descricao: string, observacao: string) {
        //let dataAtual = new Date().toLocaleString("pt-Br", {timeZone: "America/Sao_Paulo"});
        const data = { idAnexoTipo, idEntidade, nomeArquivo, descricao, observacao, data: this.getDataAtual() };
        var dataRequest = JSON.stringify(data);

        const formData = new FormData();
        formData.append('json', dataRequest);
        files.forEach((file: File) => {
            formData.append('Files', file, file.name);
        });

        let url = this.environmentUrl + 'Anexo/SetItem';
        return this.httpClient.post<MensagemErro[]>(
            url,
            formData
        );
    }

    getDataAtual(): string {
        var d = new Date();
        var strData =
            d.getFullYear() + "-" +
            ("00" + (d.getMonth() + 1)).slice(-2) + "-" +
            ("00" + d.getDate()).slice(-2) + " " +
            ("00" + d.getHours()).slice(-2) + ":" +
            ("00" + d.getMinutes()).slice(-2) + ":" +
            ("00" + d.getSeconds()).slice(-2);

        return strData;
    }
}