import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ComboBase } from 'src/app/models/comboModel';
import { MessageService, TreeNode } from 'primeng/api';
import { AtividadeDetalhe, AtividadeGeralImagemViewModel } from 'src/app/core/models/atividade/atividade-detalhe';
import { AcionamentoTipo } from 'src/app/core/models/atividade/acionamento-tipo';
import { Status } from 'src/app/models/status';
import { AtividadeClasse } from 'src/app/core/models/atividade/atividade-classe';
import { AtividadeClassificacao } from 'src/app/core/models/atividade/atividade-classificacao';
import { AtividadeFluxoTransicaoPorStatus } from 'src/app/core/models/atividade/atividade-fluxo-transicao-por-status';
import { AtividadeService } from 'src/app/core/services/atividade.service';
import { AtividadeStatusService } from 'src/app/core/services/atividade-status.service';
import { ClienteService } from 'src/app/core/services/cliente.service';
import { ProdutoService } from 'src/app/core/services/produto.service';
import { UserService } from 'src/app/core/services/user.service';
import { CaracteristicaService } from 'src/app/core/services/caracteristica.service';
import { AtividadeTipoService } from 'src/app/core/services/atividade-tipo.service';
import { AtividadeTipo } from 'src/app/models/tipo-servico';

@Component({
  selector: 'mf-atividade-form',
  templateUrl: './atividade-form.component.html',
  styleUrls: ['./atividade-form.component.scss']
})
export class AtividadeFormComponent implements OnInit, OnDestroy {


  @Input() tipoAcionamento!: string;
  disabled: boolean = true;
  @Input() enderecoCliente!: string;
  latitudeCliente!: number;
  longitudeCliente!: number;
  @ViewChild('enderecoInput') enderecoInput!: ElementRef<HTMLInputElement>;
  @ViewChild('latitudeInput') latitudeInput!: ElementRef<HTMLInputElement>;
  @ViewChild('longitudeInput') longitudeInput!: ElementRef<HTMLInputElement>;

  atividadeAddForm!: FormGroup;
  atividadeDetalhe!: AtividadeGeralImagemViewModel;
  atividadeDetalhe$!: Observable<AtividadeDetalhe>;
  @Input() atividade!: AtividadeDetalhe;
  @Input() idAtividade!: number;

  listaAcionamentos!: AcionamentoTipo[];
  selectedItemAcionamentoTipo!: AcionamentoTipo;

  listaStatus!: Status[];
  selectedItemStatus!: Status;

  listaAtividadeClasse!: AtividadeClasse[];

  listaAtividadeClassificacao!: AtividadeClassificacao[];
  listaAtividadeClassificacaoFull!: AtividadeClassificacao[];
  selectedItemAtividadeClassificacao!: AtividadeClassificacao;

  listaAtividadeTipo!: AtividadeTipo[];
  selectedItemAtividadeTipo!: AtividadeTipo;

  listaProduto!: ComboBase[];
  listaProdutoFull!: ComboBase[];
  selectedItemProduto!: ComboBase;

  listaCaracteristica!: ComboBase[];
  listaCaracteristicaFull!: ComboBase[];
  selectedItemCaracteristica!: ComboBase;

  idHierarquia!: number;
  dialogHierarquiaVisible!: boolean;
  hierarquiaLabel: string = '';

  listaCliente!: ComboBase[];
  selectedItemCliente!: ComboBase;

  public flagAdd: boolean = false;

  debounce: Subject<string> = new Subject<string>();

  listaFluxoTransicaoPorStatus: AtividadeFluxoTransicaoPorStatus[] = [];
  // #endregion

  // #region Constructor
  constructor(
    private atividadeService: AtividadeService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private atividadeStatusService: AtividadeStatusService,
    private atividadeTipoService: AtividadeTipoService,
    private caracteristicaService: CaracteristicaService,
    private clienteService: ClienteService,
    private produtoService: ProdutoService,
    private messageService: MessageService,
    private userService: UserService
  ) { }
  // #endregion Constructor

  // #region OnInit
  ngOnInit(): void {


    this.flagAdd = this.userService.hasPermission('P_MAX') || this.userService.hasPermission('R_MF_SERVICO_EDT');

    this.debounce
      .pipe(debounceTime(500))
      .subscribe(filter => {
        this.clienteService.getClientesByMatricula(filter)
          .subscribe((res: any) => {
            if (res) {
              this.listaCliente = res;
            }
          },
            (err: any) => {
              console.log(`ClienteList ` + err);
            });
      });

    this.atividadeAddForm = this.formBuilder.group({
      idAtividadePai: [''],
      numero: [''],
      objeto: [''],
      etapa: [''],
      situacao: [''],
      demandante: [''],
      solicitante: [''],
      dataCriacao: [''],
      dataUltimaTramitacao: [''],
      valorLiberado: [''],
      valorEstado: ['']
    });
  }

  ngOnDestroy(): void {
    this.debounce.unsubscribe();
  }

  // #region Atividade
  findAtividadeById() {
    this.atividadeService.findAtividadeById(this.idAtividade)
      .subscribe(
        (atv) => {
          //console.log(atv);
          this.atividade = atv;
        },
        err => {
          console.log(err);
        }
      );
  }

  resetAtividadeAddForm() {
    this.idAtividade = 0;
    this.atividadeAddForm.reset();
    this.atividadeAddForm.markAsPristine();
    this.atividadeAddForm.markAsUntouched();
    this.hierarquiaLabel = '';
    this.selectedItemStatus = <any>this.listaStatus.find(status => status.sigla.toUpperCase().trim() === 'NP');
    this.atividadeAddForm.controls['idStatus'].setValue(this.selectedItemStatus);
    this.listaProduto = this.listaProdutoFull;
    this.listaCaracteristica = this.listaCaracteristicaFull;
    this.listaAtividadeClassificacao = this.listaAtividadeClassificacaoFull;
    return false;
  }

  habilitarDesabilitarCampos() {
    this.atividadeAddForm.controls['idStatus'].disable();
    this.atividadeAddForm.controls['numeroBa'].disable();
    this.atividadeAddForm.controls['descricao'].disable();

    if (this.atividade.idAtividade > 0) {
      this.atividadeAddForm.controls['idAcionamentoTipo'].disable();
      this.atividadeAddForm.controls['idProduto'].disable();
      this.atividadeAddForm.controls['siglaAtividadeCaracteristica'].disable();
      this.atividadeAddForm.controls['idAtividadeClassificacao'].disable();
      this.atividadeAddForm.controls['idTipoAtividade'].disable();
      this.atividadeAddForm.controls['tempoExecucaoPrevisto'].disable();
      this.atividadeAddForm.controls['fatorEquivalente'].disable();
      this.atividadeAddForm.controls['idCliente'].disable();
      this.atividadeAddForm.controls['latitudeOriginal'].disable();
      this.atividadeAddForm.controls['longitudeOriginal'].disable();
      this.atividadeAddForm.controls['municipio'].disable();
      this.atividadeAddForm.controls['cenario_Prioridade'].disable();
      this.atividadeAddForm.controls['cenario_Tratamento'].disable();
      this.atividadeAddForm.controls['cenario_Tempo'].disable();
      this.atividadeAddForm.controls['cenario_Classificacao'].disable();
      this.atividadeAddForm.controls['cenario_SelecaoOS'].disable();
      this.atividadeAddForm.controls['ranqueamento'].disable();
    } else {
      if (this.atividade.idAtividade > 0) {
        this.atividadeAddForm.controls['idAcionamentoTipo'].enable();
        this.atividadeAddForm.controls['idProduto'].enable();
        this.atividadeAddForm.controls['siglaAtividadeCaracteristica'].enable();
        this.atividadeAddForm.controls['idAtividadeClassificacao'].enable();
        this.atividadeAddForm.controls['idTipoAtividade'].enable();
        this.atividadeAddForm.controls['tempoExecucaoPrevisto'].enable();
        this.atividadeAddForm.controls['fatorEquivalente'].enable();
        this.atividadeAddForm.controls['idCliente'].enable();
      }
    }
  }

  onChangeAcionamentoTipo(event: any) {
    this.tipoAcionamento = event.value != null ? event.value.sigla.toLowerCase() : '';

    this.tipoAcionamentoValidators();
  }

  tipoAcionamentoValidators() {
    const siteControl = this.atividadeAddForm.get('site');
    if (this.tipoAcionamento === 'obra' || this.tipoAcionamento === 'mnt') {
      siteControl?.setValidators([Validators.required]);
    } else {
      siteControl?.setValidators(null);
      this.atividadeAddForm.controls['idAcionamento'].setValue(null);
      this.atividadeAddForm.controls['idProvisorio'].setValue(null);
      this.atividadeAddForm.controls['site'].setValue(null);
    }

    siteControl?.updateValueAndValidity();
  }

  reciverFeedbackAcionamento(acionamento: AcionamentoTipo) {

  }

  getAtividadeClasse() {
    this.atividadeService.getAtividadeClasse().subscribe(
      (res) => {
        if (res) {
          this.listaAtividadeClasse = res;
        }
      },
      err => {
        console.log(`AtividadeClasseList ` + err);
      }
    );
  }

  showDialogHierarquia() {
    this.dialogHierarquiaVisible = true;
  }

  reciverFeedbackHierarquia(treeNode: TreeNode) {
    this.idHierarquia = Number(treeNode.key);
    this.hierarquiaLabel = <any>treeNode.label;
    this.dialogHierarquiaVisible = false;
    this.atividadeAddForm.controls['idHierarquia'].setValue(this.idHierarquia.toString());
  }

  onChangeCliente(event: any) {
    this.selectedItemCliente = event.value;
  }

  onSearchChangeAddress(value: any) {
    this.enderecoCliente = value;
  }

  onSearchChangeLatitude(value: any) {
    this.latitudeCliente = value;
  }

  onSearchChangeLongitude(value: any) {
    this.longitudeCliente = value;
  }

  addAtividade(): void { }

  saveAtividade(): void { }
}