import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ComboBase } from 'src/app/models/comboModel';
import { ActivityStage, ActivityStatus } from 'src/app/models/activity.interface';
import { Status, StatusByEtapa } from 'src/app/models/status';
import { TipoStatus } from 'src/app/models/tipo-status';


@Injectable({
    providedIn: 'root'
})
export class AtividadeStatusService {

    private environmentUrl: string = '';

    dialog = new EventEmitter();

    constructor(private httpClient: HttpClient) {
        this.environmentUrl = environment.ApiAtv;
    }

    getListaStatus(): Observable<Status[]> {

        let url = this.environmentUrl + "v1/AtividadeStatus/GetLista";
        return this.httpClient.get<Status[]>(url);
    }

    setStatus(status: Status) {

        let url = this.environmentUrl + "v1/AtividadeStatus/Salvar";
        return this.httpClient.post<number>(url, status);
    }

    getListaStatusDemanda(): Observable<ComboBase[]> {

        let url = this.environmentUrl + "v1/AtividadeStatus/demanda/combo";
        return this.httpClient.get<ComboBase[]>(url);
    }

    getListaStatusParcela(): Observable<ComboBase[]> {

        let url = this.environmentUrl + "v1/AtividadeStatus/parcela/combo";
        return this.httpClient.get<ComboBase[]>(url);
    }

    getListaStatusOS(): Observable<ComboBase[]> {

        let url = this.environmentUrl + "v1/AtividadeStatus/os/combo";
        return this.httpClient.get<ComboBase[]>(url);
    }

    getListaStatusTratamentoPendencia(): Observable<ComboBase[]> {

        let url = this.environmentUrl + "v1/AtividadeStatus/tratamentopendencia/combo";
        return this.httpClient.get<ComboBase[]>(url);
    }

    getListaStatusPlanoTrabalho(): Observable<ComboBase[]> {

        let url = this.environmentUrl + "v1/AtividadeStatus/planotrabalho/combo";
        return this.httpClient.get<ComboBase[]>(url);
    }

    findTipoStatusByIdAtividadeStatus(idAtividadeStatus: number): Observable<TipoStatus> {
        let url = this.environmentUrl + "v1/AtividadeStatus/tipostatus/" + idAtividadeStatus;
        return this.httpClient.get<TipoStatus>(url);
    }

    getTipoStatus(): Observable<TipoStatus[]> {
        let url = this.environmentUrl + "v1/AtividadeStatus/tipostatus";
        return this.httpClient.get<TipoStatus[]>(url);
    }

    delStatus(idAtividadeStatus: number) {
        let url = this.environmentUrl + "v1/AtividadeStatus/DelItem/" + idAtividadeStatus;
        return this.httpClient.delete<number>(url);
    }

    getListaStatusByEtapa(idAtividadeEtapa: number): Observable<StatusByEtapa[]> {

        let url = this.environmentUrl + "v1/AtividadeStatus/GetListaByEtapa/" + idAtividadeEtapa;
        return this.httpClient.get<StatusByEtapa[]>(url);
    }

    setStatusEtapa(status: StatusByEtapa[]) {
        let url = this.environmentUrl + "v1/AtividadeStatus/SalvarEtapa";
        return this.httpClient.post<boolean>(url, status);
    }

    getActivitiesByStage(activityId: number): Observable<Array<ActivityStage>> {
        return this.httpClient.get<Array<ActivityStage>>(`${environment.ApiAtv}v2/Atividades/Etapas/${activityId}/Status`);
    }

    getActivities(): Observable<Array<ActivityStatus>> {
        return this.httpClient.get<Array<ActivityStatus>>(`${environment.ApiAtv}v2/Atividades/Etapas/Status`);
    }
}