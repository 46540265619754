<div class="c-header">

  <div class="c-header__wrapper">
    <div class="c-header__menu-mobile" (click)="displayMenu = true">
      <i class="pi pi-bars"></i>
    </div>

    <img class="c-header__logo" src="assets/images/SP-menu.png" alt="" />
  </div>

  <span class="c-header__title">SGRI - SGC Sistema de Gestão de Convênio</span>

  <div class="c-header__profile">
    <p-avatar [label]="userAcronym" [style]="{ 'background-color': AVATAR_COLOR }"></p-avatar>

    <ng-container *ngIf="!(isDesktop$ | async)">
      <div class="c-header__profile--info">
        <span class="name">{{ currentUser.Nome }} - </span>
        <span class="function">{{ currentUser.Funcao }}</span>
      </div>
    </ng-container>

    <i class="c-header__profile--logout pi pi-sign-out" (click)="logout()"></i>
  </div>
</div>

<p-sidebar [(visible)]="displayMenu">
  <div class="container-menu">
    <div class="container-menu__back" (click)="displayMenu = false">Voltar</div>
    <p-panelMenu [model]="items" [multiple]="true"></p-panelMenu>
  </div>
</p-sidebar>