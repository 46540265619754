import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ComboBase } from 'src/app/models/comboModel';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PerfilService {

    private environmentUrl: string = '';
    dialogPerfil = new EventEmitter();

    constructor(private httpClient: HttpClient) {
        this.environmentUrl = environment.ApiCore + "v1/Perfil/";
    }

    getPerfilCombo(): Observable<ComboBase[]> {
        let url = this.environmentUrl + "combo";
        return this.httpClient.get<ComboBase[]>(url);
    }    
}