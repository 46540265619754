<p-table styleClass="p-datatable-gridlines" [value]="atividadeEvents" 
    [loading]="loading">
    <ng-template pTemplate="header">
        <tr [ngClass]="{'atividades-events-row': true}">            
            <th>Data</th>
            <th>Etapa</th>
            <th>Situação</th>
            <th>Operação</th>
            <th>Executor</th>
            <th>Localização</th>
            <th>Dias Previsto</th>
            <th>Dias Realizado</th>
            <th>Aviso / Alerta / Alarme</th>            
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-atividadeEvent>
        <tr [ngClass]="{'atividades-events-row': true}">            
            <td>{{atividadeEvent.data | date:'dd/MM/yyyy HH:mm:ss'}}</td>
            <td>
                <span [class]="'status-badge'"
                    [style]="{'background-color': '' + atividadeEvent.etapaCor + '', 'color': '#DDDDDD'}">{{atividadeEvent.etapaNome}}</span>
            </td>
            <td [ngStyle]="{'color': '#' + atividadeEvent.cor + ''}">{{atividadeEvent.nomeStatusAnterior}}</td>
            <td [style]="{'font-size': atividadeEvent.operacao == 'TRAMITAÇÃO REVERSA' ? '14px' : '12px', 'color': atividadeEvent.operacao == 'TRAMITAÇÃO REVERSA' ? 'red' : '#495057'}">{{atividadeEvent.operacao}}</td>
            <td>{{atividadeEvent.tecnicoAlocado}}</td>
            <td>{{atividadeEvent.observacao}}</td>
            <td>{{atividadeEvent.prazoPrevisto}}</td>
            <td>{{atividadeEvent.prazoRealizado}}</td>
            <td></td>
        </tr>
    </ng-template>
</p-table>