import { Component, Input } from '@angular/core';
import { BreadCrumb } from 'src/app/models/others/breadcrumb.interface';

@Component({
  selector: 'sgc-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() model: Array<BreadCrumb> = [];
}
