import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AtividadeOSParcelaVinculada } from 'src/app/core/models/atividade/atividade-os-parcela-vinculada';
import { FiltroService } from 'src/app/core/services/filtro.service';
import { UserService } from 'src/app/core/services/user.service';
import { MxlAtividadeFiltro, MxlAtividadeFiltroModel, MxlAtividadeFiltroMxlAtividade } from 'src/app/models/filtro.model';

@Component({
  selector: 'sgc-filtro-list',
  templateUrl: './filtro-list.component.html',
  styleUrls: ['./filtro-list.component.scss']
})
export class FiltroListComponent implements OnInit {
  public lista!: MxlAtividadeFiltro[];
  public selectedItem!: any;
  @Input() idAtividadeFiltro!: number;
  public filtroModel!: MxlAtividadeFiltroModel;
  public filtro!: any;
  public filtroAtividade: MxlAtividadeFiltroMxlAtividade[] = [];
  public flagDisabled: boolean = true;
  public flagEdt: boolean = false;

  public submitted!: boolean;
  public displayMsg: boolean = false;
  public displayDialog: boolean = false;
  public displayDialogEdt: boolean = false;

  dialogFiltroVisible: boolean = false;
  @Input() origem!: string;
  @Input() queryString!: string;
  @Input() listaFiltros!: string;
  @Input() idAtividades!: number[];
  @Output() itemSelecionado = new EventEmitter();
  @Output() onChange = new EventEmitter();

  buttonFiltroLabel: string = 'Incluir Filtro';
  buttonFiltroIcon: string = 'pi pi-plus';

  @Input() listaDemandas: AtividadeOSParcelaVinculada[] = [];
  numeroDemanda: string = '';
  selectedItemsDropDownEtapa: string = '';
  selectedItemsDropDownSituacao: string = '';
  selectedTipoDeConvenio: string = '';
  selectedListaPeriodo: string = '';
  dataIni!: Date;
  dataFim!: Date;
  selectedItemsDropDownMunicipio: string = '';

  showFilterPeriodo: boolean = true;

  constructor(public filtroService: FiltroService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.flagEdt = this.userService.hasPermission('R_ATIVIDADE_FILTRO_EDT');

    this.getLista();

    this.filtroService.dialogFiltro
      .subscribe(
        () => {
          this.hideDialog();
        },
        err => { }
      );

    this.filtroService.setFiltrosEmitter
      .subscribe((data) => {
        if (data.clear == true) {
          this.selectedItem = null;
        } else {
          this.queryString = data.queryString;
          this.listaFiltros = data.listaFiltros;
          this.listaDemandas = data.listaDemandas;
          this.getListaFiltrosGravados();
        }
      });
  }

  getLista() {
    this.filtroService.getLista(this.origem)
      .subscribe((res) => {
        this.lista = res;
        if (this.filtro?.descricao != '') {
          this.filtro = this.lista.filter(f => f.descricao == this.filtro?.descricao).shift();
          this.selectedItem = this.filtro;

          this.onChange.emit({ value: this.selectedItem });
        }
      });

    this.buttonFiltroLabel = 'Incluir Filtro';
    this.buttonFiltroIcon = 'pi pi-plus';
  }

  addItem() {
    this.filtroModel = {} as MxlAtividadeFiltroModel;
    this.filtro = {} as MxlAtividadeFiltro;
    this.submitted = false;
    this.displayDialog = true;
  }

  setItem() {

    if ((this.queryString !== undefined && this.queryString != null && this.queryString.length > 2000) && this.idAtividades.length == 0) {
      this.messageService.add({ key: 'toastFiltro', severity: 'warn', summary: 'Aviso', detail: 'Restrinja o Filtro ou selecione as Demandas para salvar.', life: 6000 });
      return;
    } else if (this.queryString == '' && this.idAtividades.length == 0) {
      this.messageService.add({ key: 'toastFiltro', severity: 'warn', summary: 'Aviso', detail: 'Selecione pelo menos um filtro, o período ou a(s) Demanda(s).', life: 6000 });
      return;
    }

    this.flagDisabled = true;
    const msg = this.filtro!.idAtividadeFiltro > 0 ? 'Filtro atualizado com sucesso.' : 'Filtro inserido com sucesso.';

    this.filtro!.queryString = this.queryString;
    this.filtro!.origem = this.origem;
    this.filtro!.listaFiltros = this.listaFiltros;

    this.filtroAtividade = [];
    this.idAtividades.forEach(f => {
      this.filtroAtividade.push({
        idAtividadeFiltroMxlAtividade: 0,
        idAtividadeFiltro: this.filtro!.idAtividadeFiltro === undefined || this.filtro!.idAtividadeFiltro == null ? 0 : this.filtro!.idAtividadeFiltro,
        idAtividade: f
      });
    });

    this.filtroModel = { mxlAtividadeFiltro: this.filtro, mxlAtividadeFiltroMxlAtividade: this.filtroAtividade };

    this.filtroService.setItem(this.filtroModel)
      .subscribe(
        result => {
          if (result < 0) {
            this.messageService.add({ key: 'toastFiltro', severity: 'error', summary: 'Erro', detail: 'Filtro já existe.', life: 3000 });
            //this.hideDialog();
          }
          else if (result > 0) {
            this.selectedItem = null;
            this.messageService.add({ key: 'toastFiltro', severity: 'success', summary: 'Sucesso', detail: msg, life: 3000 });
            this.dialogFiltroVisible = false;
            this.getLista();
          } else {
            this.messageService.add({ key: 'toastFiltro', severity: 'error', summary: 'Erro', detail: 'Erro ao salvar o Filtro', life: 6000 });
            this.flagDisabled = false;
          }
        },
        err => {
          this.messageService.add({ key: 'toastFiltro', severity: 'error', summary: 'Erro', detail: 'Erro ao ' + (this.filtro!.idAtividadeFiltro > 0 ? 'atualizar' : 'inserir') + ' o Filtro.', life: 3000 });
          this.flagDisabled = false;
        }
      );
  }

  updItem(filtro: MxlAtividadeFiltro) {
    this.filtro = { ...filtro };
    this.displayDialog = true;

    //this.itemSelecionado.emit(filtro.idAtividadeFiltro);
  }

  hideDialog() {
    this.submitted = false;
    this.dialogFiltroVisible = false;
    //this.filtro = {} as MxlAtividadeFiltro;
    //this.getLista();
    //this.filtro = {} as MxlAtividadeFiltro;
    //this.itemSelecionado.emit();
  }

  delItem(idAtividadeFiltro: number) {
    this.confirmationService.confirm({
      message: 'Confirma a remoção do Item?',
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => {
        this.filtroService.delItem(idAtividadeFiltro)
          .subscribe(
            result => {
              if (result == true) {
                this.messageService.add({ key: 'toastFiltro', severity: 'success', summary: 'Sucesso', detail: 'Filtro removido com sucesso.', life: 3000 });
                this.dialogFiltroVisible = false;
                this.filtro = {} as MxlAtividadeFiltro;
                this.getLista();
                this.onChange.emit({ value: 'DELETE' });
              } else {
                this.messageService.add({ key: 'toastFiltro', severity: 'error', summary: 'Erro', detail: 'Erro ao remover filtro.', life: 6000 });
                this.flagDisabled = false;
              }
            },
            err => {
              this.messageService.add({ key: 'toastFiltro', severity: 'error', summary: 'Erro', detail: 'Erro ao remover o Filtro.', life: 3000 });
            });
      },
      reject: () => { }
    });
  }

  onChangeFiltro(event: any) {
    if (event.value != null) {
      this.buttonFiltroLabel = 'Editar Filtro';
      this.buttonFiltroIcon = 'pi pi-pencil';
      this.filtro = event.value;
    } else {
      this.buttonFiltroLabel = 'Incluir Filtro';
      this.buttonFiltroIcon = 'pi pi-plus';
    }
    this.onChange.emit(event);
  }

  dialogFiltroEditar(event: any) {
    this.getListaFiltrosGravados();

    this.filtroService.setQueryStringFiltroEmitter.emit();
  }

  dialogFiltroInserir(event: any) {
    this.selectedItem = null;
    this.addItem();

    this.getListaFiltrosGravados();

    this.dialogFiltroVisible = true;
    this.filtroService.setQueryStringFiltroEmitter.emit();
  }

  getListaFiltrosGravados() {

    if (this.listaFiltros === undefined || this.listaFiltros == null || this.listaFiltros == '') {
      this.dialogFiltroVisible = true;
      return;
    }

    var Lista = JSON.parse(this.listaFiltros);

    if (Lista.length > 0) {
      this.numeroDemanda = Lista[0];
      if (Lista[12] != null) {
        this.selectedItemsDropDownEtapa = JSON.parse(Lista[12]).map((m: any) => m.text).join(', ');
      }
      if (Lista[13] != null) {
        this.selectedItemsDropDownSituacao = JSON.parse(Lista[13]).map((m: any) => m.text).join(', ');
      }
      this.selectedTipoDeConvenio = JSON.parse(Lista[14]);
      this.dataIni = Lista[15];
      this.dataFim = Lista[16];
      this.selectedListaPeriodo = JSON.parse(Lista[17]);
      if (Lista[18] != null) {
        this.selectedItemsDropDownMunicipio = JSON.parse(Lista[18]).map((m: any) => m.label).join(', ');
      }
    }

    this.dialogFiltroVisible = true;
  }
}