<p-toast [style]="{'text-align': 'left'}" key="toastFiltro"></p-toast>

<!-- <div class="formgroup-inline"> -->
<label class="font-bold">Selecione o Filtro: </label>
<p-dropdown [options]="lista" [(ngModel)]="selectedItem" placeholder="Selecione o Filtro" optionLabel="descricao"
    [showClear]="true" class="class" autoWidth="false" [style]="{'font-size': '12px !important'}"
    (onChange)="onChangeFiltro($event)">
</p-dropdown>
<button pButton pRipple label="Inserir novo Filtro" icon="pi pi-plus"
    class="removeButton p-button-info ml-2 mr-2 greenInput" (click)="dialogFiltroInserir(true)"></button>
<button pButton pRipple label="Editar Filtro" icon="pi pi-pencil"
    [disabled]="selectedItem === undefined || selectedItem == null"
    class="removeButton p-button-primary mr-2 greenInput" (click)="dialogFiltroEditar(true)"></button>

<!-- </div> -->

<p-dialog *ngIf="dialogFiltroVisible" header="Salvar Filtro" [(visible)]="dialogFiltroVisible" [style]="{width: '60vw'}"
    [baseZIndex]="10001" [maximizable]="true" [modal]="true" [resizable]="true"
    [contentStyle]="{height: '600px', 'text-align': 'left'}" styleClass="fluid">

    <ng-template pTemplate="body">

        <div class="card">
            <div class="formgroup-inline">
                <div class="col-6">
                    <label class="font-bold" for="nomeFiltro">Nome do Filtro:</label>
                    <input id="nomeFiltro" style="width: 100%;" type="text" pInputText [(ngModel)]="filtro.descricao" />
                    Selecione as Demandas para gravar no filtro.
                </div>
                <div class="col-2 text-right" style="margin-top: 15px;">
                    <button type="button" pButton pRipple label="Cancelar" icon="pi pi-times" pTooltip="Cancelar"
                        style="width: 100%;" class="p-button-danger mr-2" (click)="hideDialog()"></button>
                </div>
                <div class="col-2 text-right" style="margin-top: 15px;">
                    <button type="button" pButton pRipple label="Remover" icon="pi pi-trash" style="width: 100%;"
                        class="p-button-warning mr-2" matTooltip="Remover Filtro"
                        [disabled]="!flagEdt || !filtro.idAtividadeFiltro" pTooltip="Remover Filtro"
                        (click)="delItem(filtro.idAtividadeFiltro)"></button>
                </div>
                <div class="col-2 text-right" style="margin-top: 15px;">
                    <button type="button" pButton pRipple label="Confirmar" icon="pi pi-check" style="width: 100%;"
                        class="p-button-success" [disabled]="filtro.descricao === undefined || filtro.descricao == ''"
                        pTooltip="Confirmar" (click)="setItem()"></button>
                </div>
            </div>
            <p-confirmDialog [style]="{width: '500px'}"></p-confirmDialog>

            <div class="col-12" *ngIf="listaDemandas !== undefined && listaDemandas.length > 0">
                <p-table #dt [value]="listaDemandas" dataKey="idAtividade" [paginator]="true" [rows]="50"
                    [showCurrentPageReport]="true" [scrollable]="true" scrollHeight="40vh"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                    [rowsPerPageOptions]="[50,100,200]" [globalFilterFields]="['descricao']"
                    [tableStyle]="{ 'font-size': '12px' }"
                    styleClass="p-datatable-sm p-datatable-responsive-demo p-datatable-striped" [responsive]='true'>

                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 12%;" pSortableColumn="numero">Demanda<p-sortIcon
                                    field="numero"></p-sortIcon>
                            </th>
                            <th style="width: 22%;" pSortableColumn="emenda">Emenda<p-sortIcon
                                    field="emenda"></p-sortIcon>
                            </th>
                            <th style="width: 22%;" pSortableColumn="demandante">Prefeitura<p-sortIcon
                                    field="demandante"></p-sortIcon>
                            </th>
                            <th style="width: 22%;">Etapa</th>
                            <th style="width: 22%;">Situação</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-dem>
                        <tr>
                            <td class="break-word">{{dem.numero}}</td>
                            <td class="break-word">{{dem.emenda}}</td>
                            <td class="break-word">{{dem.demandante}}</td>
                            <td class="break-word">{{dem.etapa}}</td>
                            <td class="break-word">{{dem.situacao}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>

        <div class="card col-12" *ngIf="listaDemandas !== undefined && listaDemandas.length == 0">
            <div class="formgroup-inline" style="font-size: 14px;">
                <div class="col-3">
                    <label class="font-bold">Demanda:</label><br>
                    [{{numeroDemanda}}]
                </div>
                <div class="col-3">
                    <label class="font-bold">Tipo de Convênio:</label><br>
                    [{{selectedTipoDeConvenio}}]
                </div>
                <div class="col-6">
                    <div style="float: left;">
                        <label class="font-bold">Período:</label><br>
                        [{{selectedListaPeriodo}}]
                    </div>
                    <div class="ml-4 mr-2" style="float: left;" *ngIf="showFilterPeriodo">
                        <label class="font-bold">Início:</label><br>
                        [{{dataIni | date:'dd/MM/yyyy'}}]

                    </div>
                    <div *ngIf="showFilterPeriodo">
                        <label class="font-bold">Término:</label><br>
                        [{{dataFim | date:'dd/MM/yyyy'}}]
                    </div>
                </div>
            </div>
            <div class="formgroup-inline">
                <div class="col-12">
                    <label class="font-bold">Etapa:</label><br>
                    [{{selectedItemsDropDownEtapa}}]
                </div>
                <div class="col-12">
                    <label class="font-bold">Situação da Demanda:</label><br>
                    [{{selectedItemsDropDownSituacao}}]
                </div>
                <div class="col-12">
                    <label class="font-bold">Município:</label><br>
                    [{{selectedItemsDropDownMunicipio}}]
                </div>
            </div>

        </div>

    </ng-template>
    <!-- <ng-template pTemplate="footer">        
    </ng-template> -->
</p-dialog>