import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AtividadeTipoService } from 'src/app/core/services/atividade-tipo.service';
import { PerfilService } from 'src/app/core/services/perfil.service';
import { ComboBase } from 'src/app/models/comboModel';

@Component({
    selector: 'sgc-perfil-multiselect',
    templateUrl: './perfil-multiselect.component.html'
})
export class PerfilMultiselectComponent implements OnInit {

    list: ComboBase[] = [];
    selectedList: ComboBase[] = [];
    @Input() selectedItem: ComboBase[] = [];
    @Output() itemSelecionado = new EventEmitter();

    @Input() itensSelecionados!: number[];

    ngOnInit(): void {
        this.getPerfis();
    }

    constructor(private perfilService: PerfilService) {}

    getPerfis() {
        this.perfilService.getPerfilCombo()
            .subscribe(
                (res) => {
                    if (res) {                        
                        this.list = res;
                        if (this.itensSelecionados !== undefined && this.itensSelecionados.length > 0 && this.list.length > 0) {
                            this.selectedList = this.list.filter(x => this.itensSelecionados.includes(parseInt(x.value ?? '0')));
                        } else {
                            this.selectedList = this.selectedItem;
                        }
                    }
                },
                err => {
                    console.log(`PerfilMultiSelectList ` + err);
                }
            );
    }   
    
    onChange(event: any) {
        if (event.value !== null && event.value !== undefined) {   
            this.itemSelecionado.emit(event.value);
        }
    }
}