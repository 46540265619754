import { Injectable } from '@angular/core';
import { config } from './../../configs/config';

import { jwtDecode } from 'jwt-decode';

const KEY = config.aplicacao + '-authToken';

@Injectable({ providedIn: 'root' })
export class TokenService {
  hasToken() {
    return !!this.getToken();
  }

  setToken(token: string) {
    //console.log('setToken', token);
    window.localStorage.setItem(KEY, token);
  }

  getToken(): any {
    return window.localStorage.getItem(KEY);
  }

  removeToken() {
    //console.log('removeToken');
    window.localStorage.removeItem(KEY);
  }

  getTokenExpirationDate(): Date | null {
    const decoded = jwtDecode(this.getToken());

    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: any): any {
    if (!token) token = this.getToken();
    if (!token) return true;

    const date = this.getTokenExpirationDate();

    if (date === undefined) return false;

    if (date) return !(date.valueOf() > new Date().valueOf());
  }
}
