import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ResponsavelOrdemServicoComboService } from './responsavel-ordem-servico-combo.service';
import { ComboBase } from 'src/app/models/comboModel';

@Component({
    selector: 'max-responsavel-ordem-servico-combo',
    templateUrl: './responsavel-ordem-servico-combo.component.html'
})
export class ResponsavelOrdemServicoComboComponent implements OnInit {

    public lista!: ComboBase[];
    public selectedItem!: ComboBase;
    @Input() code: string = "";
    @Input() class!: string;
    @Input() selectedItemForm!: ComboBase;
    @Output() aoLimparSelecionado = new EventEmitter();
    @Output() itemSelecionado = new EventEmitter();

    constructor(private service: ResponsavelOrdemServicoComboService) { }

    ngOnInit(): void {
        this.code = "";


        if (this.selectedItemForm != null) {
            this.code = this.selectedItemForm.code ? this.selectedItemForm.code : '';
            this.selectedItem = this.selectedItemForm;
        }
        this.getLista();
    }

    getLista() {
        this.service.getLista()
            .subscribe(
                (res) => {
                    if (res) {
                        this.lista = res;
                    }
                },
                err => {
                    console.log(`EtapaList ` + err);
                }
            );
    }

    onClear(event: any) {
        this.aoLimparSelecionado.emit(event);
    }
    onChange(event: any) {
        this.itemSelecionado.emit(event.value);
    }
}