import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    this.onValidadeUser();
  }

  onValidadeUser(): boolean {
    const token = localStorage.getItem('maxline-authToken');

    return token ? true : false;
  }
}
