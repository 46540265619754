import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ComboBase } from 'src/app/models/comboModel';
import { AtividadeTipo } from 'src/app/models/tipo-servico';

@Injectable({
    providedIn: 'root'
})
export class AtividadeTipoService {

    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient) {
        this.environmentUrl = environment.ApiCore;
    }

    getListaTipoServico(): Observable<AtividadeTipo[]> {

        let url = this.environmentUrl + "v1/AtividadeTipo/";
        return this.httpClient.get<AtividadeTipo[]>(url);
    }

    getListaTipoServicoPorProdOuCarac(idProduto?: number, idCaracteristica?: number): Observable<AtividadeTipo[]> {

        let url = this.environmentUrl + "v1/AtividadeTipo/GetAtividadeTipoPorProdOuCarac/" + idProduto + "/" + idCaracteristica;
        return this.httpClient.get<AtividadeTipo[]>(url);
    }

    getListaCombo(): Observable<ComboBase[]> {

        let url = this.environmentUrl + "v1/AtividadeTipo/combo";
        return this.httpClient.get<ComboBase[]>(url);
    }
}