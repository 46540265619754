import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Menu, MenuItem } from 'src/app/models/others/menu.interface';
import { MENU } from '../../constants/menu';

@Component({
  selector: 'sgc-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  private readonly TAG_DATA = 'menu';

  constructor(private router: Router) { }

  menu: Array<Menu> = MENU;

  onSelectItem(menu: Menu): void {
    this.onResetMenu();

    menu.selected = !menu.selected;

    if (!menu.subMenu) {
      this.router.navigate([menu.route]);
    } else {
      menu.activeSubMenu = !menu.activeSubMenu;
    }
  }

  onSelectSubItem(menu: MenuItem): void {
    this.router.navigate([menu.route]);
  }

  onResetMenu(): void {
    this.menu.map((item) => (item.selected = false));
  }

  onResetSubMenu(): void {
    this.menu.map((item) => (item.activeSubMenu = false));
  }

  @HostListener('document:click', ['$event'])
  handleEvent(event: any): void {
    const menuElement = event?.target?.attributes?.data?.value;

    if (menuElement !== this.TAG_DATA) {
      this.menu.map(() => this.onResetSubMenu());
    }
  }
}
