<p-dropdown 
    [options]="lista" 
    [(ngModel)]="selectedItem"
    placeholder="Selecione" 
    optionLabel="text" 
    [showClear]="true"
    class="class"    
    autoWidth="false" 
    [style]="{'width':'100%'}"
    (onClear)="onClear($event)"
    (onChange)="onChange($event)"    
    >
</p-dropdown>