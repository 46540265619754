import { Menu } from "src/app/models/others/menu.interface";

export const MENU: Array<Menu> = [
    {
        icon: 'pi pi-home',
        tooltip: '',
        selected: false,
        route: '/',
    },
    {
        icon: 'pi pi-book',
        tooltip: '',
        selected: false,
        route: '/demandas',
    },
    {
        icon: 'pi pi-cog',
        tooltip: '',
        selected: false,
        activeSubMenu: false,
        subMenu: [
            {
                label: 'Etapas',
                icon: 'pi pi-sort-alt',
                tooltip: '',
                route: '/etapa/lista',
            },
            {
                label: 'Checklist',
                icon: 'pi pi-check-square',
                tooltip: '',
                route: '/checklist/templates'
            },
            {
                label: 'Itens do Checklist',
                icon: 'pi pi-check-circle',
                tooltip: '',
                route: '/checklist/template/layout'
            },
            {
                label: 'Fluxo',
                icon: 'pi pi-arrow-right-arrow-left',
                tooltip: '',
                route: '/transition-flow/fluxo'
            }
        ],
    },
    {
        icon: 'pi pi-clock',
        tooltip: '',
        selected: false,
        route: '/tratamento-prazos',
    },
    {
        icon: 'pi pi-exclamation-circle',
        tooltip: '',
        selected: false,
        route: '/tratamento-diligencias',
    },
    {
        icon: 'pi pi-print',
        tooltip: '',
        selected: false,
        route: '/relatorio-quantitativo',
    },
    {
        icon: 'pi pi-calendar',
        tooltip: 'Agendamento',
        selected: false,
        route: '/agendamento',
    }
];