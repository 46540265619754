import { inject } from '@angular/core';
import { ActivatedRoute, CanActivateFn, Router } from '@angular/router';
import { TokenService } from '../services/token.service';
import { UserService } from '../services/user.service';

export const AuthenticationGuard: CanActivateFn = (route, state) => {

  const router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);
  const tokenService: TokenService = inject(TokenService);
  const userService: UserService = inject(UserService);
      

  var firstAcess = localStorage.getItem("firstAcess") != undefined && localStorage.getItem("firstAcess") != null;
    if (firstAcess) {
        localStorage.removeItem("maxline-authToken")
        localStorage.removeItem("firstAcess")
        router.parseUrl('/');
    }

  if (tokenService.hasToken() && tokenService.isTokenExpired()) 
  {   
    tokenService.removeToken();
    router.navigate(
      ['/login'],
      {
          queryParams: {
              fromUrl: state.url
          }
      }
    );

    return false;
  } 
  
  if(userService.isLogged()) {     
      return true;
  } else {
    router.navigate(
        ['/login'],
        {
            queryParams: {
                fromUrl: state.url
            }
        }
    );
      return false;
  }
};