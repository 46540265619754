import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AtividadeEvents } from 'src/app/models/atividade-events';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AtividadeEventsService {

    private environmentUrl: string = '';

    constructor(
        private httpClient: HttpClient
    ) {
        this.environmentUrl = environment.ApiAtv;
    }

    getAtividadeEventos(idAtividade: number): Observable<AtividadeEvents[]> {
        let url = this.environmentUrl + "v1/Atividade/GetAtividadeEventos/" + idAtividade;
        return this.httpClient.get<AtividadeEvents[]>(url);
    }
}