<!-- <p-toast position="top-right" life='30000'></p-toast> -->
<div *ngIf="atividade">
    <form #form="ngForm" [formGroup]="atividadeAddForm"
        (submit)="((atividade.idAtividade > 0 ? saveAtividade(): addAtividade() ))">
        <p-fieldset [toggleable]="true">
            <ng-template pTemplate="header">
                <span class="titulo-legend">
                    Dados Demanda
                </span>
            </ng-template>
            <div class="card" class="dados-demanda">
                <div class="grid">
                    <div class="col-3">
                        <label>Número:</label><br />
                        {{atividade.numero}}
                    </div>
                    <div class="col-3">
                        <label>Objeto:</label><br />
                        {{atividade.objeto}}
                    </div>
                    <div class="col-3">
                        <label>Processo:</label><br />
                        {{atividade.processo}}
                    </div>
                    <div class="col-3">
                        <label>Programa:</label><br />
                        {{atividade.programa}}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-3">
                        <label>Convênio:</label><br />
                        {{atividade.convenio}}
                    </div>
                    <div class="col-3">
                        <label>Etapa:</label><br />
                        <span [class]="'status-badge'"
                            [style]="{'background-color': '' + atividade.corEtapa + '', 'color': '#DDDDDD'}">{{atividade.etapa}}
                        </span>
                    </div>
                    <div class="col-3">
                        <label>Situação:</label><br />
                        {{atividade.situacao}}
                    </div>
                    <div class="col-3">
                        <label>Prioritária de Governo:</label><br />
                        {{atividade.prioritariaDeGoverno == true ? "SIM" : "NÃO"}}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-6">
                        <label>Secretaria:</label><br />
                        {{atividade.nomeEmpresa}}
                    </div>
                    <div class="col-6">
                        <label>Demandante:</label><br />
                        {{atividade.cliente}}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-3">
                        <label>Solicitante:</label><br />
                        {{atividade.solicitante}}
                    </div>
                    <div class="col-3">
                        <label>Data Criação:</label><br />
                        {{atividade.dataCriacao | date:'dd/MM/yyyy HH:mm'}}
                    </div>
                    <div class="col-3">
                        <label for="dataUltimaTramitacao">Data última tramitação:</label><br />
                        {{atividade.dataUltimaTramitacao | date:'dd/MM/yyyy HH:mm'}}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-3">
                        <label>Valor contrapartida:</label><br />
                        {{atividade.valorContrapartida == null ? 'R$ 0,00' :
                        atividade.valorContrapartida.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                    </div>
                    <div class="col-3">
                        <label>Valor Estado:</label><br />
                        {{atividade.valorEstado == null ? 'R$ 0,00' :
                        atividade.valorEstado.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                    </div>
                    <div class="col-3">
                        <label>Valor Total:</label><br />
                        {{atividade.valorTotal == null ? 'R$ 0,00' : atividade.valorTotal.toLocaleString('pt-br',{style:
                        'currency', currency: 'BRL'})}}
                    </div>
                    <div class="col-3">
                        <label>Valor Total de Parcelas Aprovadas:</label><br />
                        {{atividade.valorAprovado == null ? 'R$ 0,00' :
                        atividade.valorAprovado.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                    </div>
                </div>
                <div class="grid">
                    <div class="col-6">
                        <label>Nome da Obra:</label><br />
                        {{atividade.nomeDaObra}}
                    </div>
                    <div class="col-3">
                        <label>Origem:</label><br />
                        {{atividade.origem}}
                    </div>
                    <div class="col-3">
                        <label>Data Assinatura:</label><br />
                        {{atividade.dataAssinatura | date:'dd/MM/yyyy'}}
                    </div>
                </div>
            </div>
        </p-fieldset>

        <div style="overflow-x: auto; max-width: 100%; display: block;">
            <p-fieldset [toggleable]="true" *ngIf="(atividade.idAtividade > 0)">
                <ng-template pTemplate="header">
                    <span class="titulo-legend">
                        Eventos
                    </span>
                </ng-template>

                <div class="formgroup-inline">
                    <mf-atividade-events [idAtividade]="atividade.idAtividade"></mf-atividade-events>
                </div>

            </p-fieldset>
        </div>

    </form>
</div>