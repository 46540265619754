import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AtividadeEventsService } from 'src/app/core/services/atividade-events.service';
import { AtividadeService } from 'src/app/core/services/atividade.service';
import { AtividadeEvents } from 'src/app/models/atividade-events';

@Component({
    selector: 'mf-atividade-events',
    templateUrl: './atividade-events.component.html',
    styleUrls: ['atividade-events.component.scss']
})
export class AtividadeEventsComponent implements OnInit, OnDestroy {
    @Input() idAtividade!: number;
    @Input() tramitacaoReversa!: boolean;
    @Input() dadosTramitacaoReversa!: any;

    atividadeEvents!: AtividadeEvents[];
    loading!: boolean;

    atividadeEventsTramitacaoReversa: AtividadeEvents = {} as AtividadeEvents;

    demandaOnLoadEmitter!: any;

    constructor(
        private atividadeEventsService: AtividadeEventsService,
        private atividadeService: AtividadeService
    ) { }

    ngOnDestroy(): void {
        if (this.demandaOnLoadEmitter !== undefined) {
            this.demandaOnLoadEmitter.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.getAtividadeEventos();

        this.demandaOnLoadEmitter = this.atividadeService.atividadeEvents
            .subscribe(() => {
                this.getAtividadeEventos();
            },
                err => { }
            );

        this.atividadeService.demandaOnLoad
            .subscribe(data => {
                this.idAtividade = data.idAtividade;
                this.atividadeEvents = [];
                this.getAtividadeEventos();
            });
    }

    getAtividadeEventos() {
        this.loading = true;
        this.atividadeEventsService.getAtividadeEventos(this.idAtividade)
            .subscribe(data => {
                if (this.tramitacaoReversa) {
                    this.atividadeEventsTramitacaoReversa.idAtividade = this.idAtividade;
                    this.atividadeEventsTramitacaoReversa.nomeStatusAnterior = this.dadosTramitacaoReversa.nomeStatus;
                    this.atividadeEventsTramitacaoReversa.etapaNome = this.dadosTramitacaoReversa.etapaNome;
                    this.atividadeEventsTramitacaoReversa.etapaCor = this.dadosTramitacaoReversa.etapaCor;
                    this.atividadeEventsTramitacaoReversa.operacao = 'TRAMITAÇÃO REVERSA';

                    data.push(this.atividadeEventsTramitacaoReversa);
                }

                this.atividadeEvents = data;
                this.loading = false;
            }
            );
    }
}