<p-fieldset [toggleable]="true">
    <ng-template pTemplate="header">
        <span class="titulo-legend">
            Dados Parcela
        </span>
    </ng-template>
    <div class="card">
        <div class="formgroup-inline">
            <div class="col-3">
                <label style="font-weight: bold;">Número:</label><br />
                {{atividadeParcela.numero}}
            </div>
            <div class="col-2">
                <label style="font-weight: bold;">Parcela:</label><br />
                {{parcela}}
            </div>
            <div class="col-3">
                <label style="font-weight: bold;">Referência:</label><br />
                {{referencia}}
            </div>
            <div class="col-2">
                <label style="font-weight: bold;">Valor Total:</label><br />
                {{ atividadeParcela.valorTotal == null ? 'R$ 0,00' :
                atividadeParcela.valorTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
            </div>
            <div class="col-2">
                <label style="font-weight: bold;">Valor Aprovado:</label><br />
                {{ atividadeParcela.valorLiberado == null ? 'R$ 0,00' :
                atividadeParcela.valorLiberado.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
            </div>
        </div>
        <div class="formgroup-inline">
            <div class="col-12">
                <label style="font-weight: bold;">Estado Atual:</label><br />
                {{estadoAtual}}
            </div>
        </div>
    </div>
</p-fieldset>

<p-fieldset [toggleable]="true">
    <ng-template pTemplate="header">
        <span class="titulo-legend">
            Eventos
        </span>
    </ng-template>
    <div class="card">
        <mf-atividade-events [idAtividade]="atividadeParcela.idAtividade"></mf-atividade-events>
    </div>
</p-fieldset>