import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ComboBase } from 'src/app/models/comboModel';
import { AtividadeEtapa } from '../models/atividade/atividade-etapa';


@Injectable({
    providedIn: 'root'
})
export class AtividadeEtapaService {

    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient) {
        this.environmentUrl = environment.ApiAtv;
    }

    getListaEtapa(): Observable<AtividadeEtapa[]> {

        let url = this.environmentUrl + "/AtividadeEtapa";
        return this.httpClient.get<AtividadeEtapa[]>(url);
    }

    getListaEtapaCombo(): Observable<ComboBase[]> {

        let url = this.environmentUrl + "v1/AtividadeEtapa/combo";
        return this.httpClient.get<ComboBase[]>(url);
    }
}