import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { CalendarOptions, DateSelectArg, EventApi, EventClickArg } from "@fullcalendar/core";
import { AtividadeDetalhe } from "src/app/core/models/atividade/atividade-detalhe";
import { AtividadeService } from "src/app/core/services/atividade.service";
import { createEventId } from "./event-utils";

import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';


@Component({
    selector: 'sgc-gantt',
    templateUrl: './gantt.component.html',
    styleUrls: ['./gantt.component.scss']
})
export class GanttComponent implements OnInit {

    @Input() idAtividade!: number;
    @Input() atividade!: AtividadeDetalhe;

    resources: any[] = [];
    events: any[] = [];
    calendarOptions!: CalendarOptions;
    calendarVisible: boolean = false;

    currentEvents: EventApi[] = [];
    eventGuid = 9999;

    duration = 0;
    index = 0;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private atividadeService: AtividadeService,
        public datepipe: DatePipe
    ) { }

    ngOnInit(): void {
        this.getAtividadeEtapa();

        this.atividadeService.demandaOnLoad
            .subscribe(data => {
                if (data) {
                    this.calendarVisible = false;

                    this.resources = [];
                    this.events = [];
                    const { calendarOptions } = this;
                    calendarOptions.resources = [];
                    calendarOptions.events = [];
                    if (data.idAtividade > 0) {

                        calendarOptions.initialDate = this.events.length > 0 ? this.events[0].start : new Date();
                        calendarOptions.scrollTime = this.events.length > 0 ? this.events[0].start.toString().split('T')[1] : new Date().toString().split('T')[1];

                        this.idAtividade = data.idAtividade;
                        this.getAtividadeEtapa();
                    }
                }
            });
    }

    handleCalendarToggle() {
        this.calendarVisible = !this.calendarVisible;
    }

    handleWeekendsToggle() {
        const { calendarOptions } = this;
        calendarOptions.weekends = !calendarOptions.weekends;
    }

    handleDateSelect(selectInfo: DateSelectArg) {
        const title = prompt('Please enter a new title for your event');
        const calendarApi = selectInfo.view.calendar;

        calendarApi.unselect(); // clear date selection

        if (title) {
            calendarApi.addEvent({
                id: createEventId(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            });
        }
    }

    handleEventClick(clickInfo: EventClickArg) {
        if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
            clickInfo.event.remove();
        }
    }

    handleEvents(events: EventApi[]) {
        this.currentEvents = events;
        this.changeDetector.detectChanges();
    }

    loadCalendar() {
        this.calendarOptions = {
            buttonText: {
                today: 'hoje',
                month: 'mês',
                year: 'ano',
                week: 'semana',
                day: 'dia',
                list: 'lista',
                resourceTimelineYear: 'ano'
            },
            timeZone: 'UTC',
            locale: 'pt-br',
            aspectRatio: 1.5,
            expandRows: false,
            height: '100%',
            eventOverlap: false,
            eventMouseEnter: function (calEvent: any) {

                calEvent.el.title = 'Etapa: ' + calEvent.event.extendedProps.etapa + '\n' +
                    'Início: ' + calEvent.event.start.toLocaleDateString() + ' ' + calEvent.event.start.toLocaleTimeString() + '\n' +
                    'Fim: ' + calEvent.event.end.toLocaleDateString() + ' ' + calEvent.event.end.toLocaleTimeString();
            },
            eventDidMount(arg: any) {
                if (arg.view.type == 'resourceTimelineYear') {

                    let calendarMonthStart = new Date(arg.event.start.getFullYear() + '-' + (arg.event.start.getMonth() + 1) + '-01');

                    let dateStart = new Date(arg.event.start);
                    let dateEnd = new Date(arg.event.end);
                    let timeDiff = Math.abs(dateEnd.getTime() - dateStart.getTime());
                    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

                    let marginLeftTimeDiff = Math.abs(dateStart.getTime() - calendarMonthStart.getTime());
                    let marginLeftDiffDays = Math.ceil(marginLeftTimeDiff / (1000 * 3600 * 24));

                    arg.el.style.width = diffDays.toString() + 'px';
                    arg.el.style.marginLeft = (marginLeftDiffDays).toString() + 'px';
                } else if (arg.view.type == 'listYear') {
                    arg.el.querySelector('.fc-list-event-title b').innerHTML += ` ${arg.event.extendedProps.etapa}`
                }
            },
            initialDate: this.events.length > 0 ? this.events[0].start : new Date(),
            scrollTime: this.events.length > 0 ? this.events[0].start.toString().split('T')[1] : new Date().toString().split('T')[1],
            displayEventTime: false,
            views: {
                resourceTimelineYear: {
                    duration: { years: this.duration }, // Or whatever value you want.
                    slotDuration: { months: 1 },
                    slotLabelInterval: { months: 1 },
                    slotMaxTime: '00:15:00',
                    slotWidth: "5%",
                    eventOverlap: true,
                    minTime: '00:00:00',
                    maxTime: "24:00:00",
                    slotEventOverlap: false,
                },
                resourceTimelineMonth: {
                    duration: { months: 1 }, // Or whatever value you want.
                    slotDuration: { months: 1 },
                    slotLabelInterval: { days: 1 },
                    slotMaxTime: '00:15:00',
                    slotWidth: "5%",
                    eventOverlap: true,
                    minTime: '00:00:00',
                    maxTime: "24:00:00",
                    slotEventOverlap: true,
                },
                resourceTimelineWeek: {
                    slotDuration: { days: 7 }
                },
                resourceTimelineDay: {
                    slotDuration: { hours: 1 }
                }
            },
            plugins: [
                interactionPlugin,
                dayGridPlugin,
                timeGridPlugin,
                listPlugin,
                resourceTimelinePlugin
            ],
            headerToolbar: {
                left: '',
                center: 'title',
                right: ''
            },

            initialView: 'resourceTimelineYear',
            editable: true,
            selectable: true,
            selectMirror: true,
            dayMaxEvents: true,
            resourceAreaWidth: "50%",

            resourceLabelDidMount: function (info: any) {
                info.el.style.color = info.resource.extendedProps.cor;
            },

            refetchResourcesOnNavigate: true,

            resourcesInitiallyExpanded: false,
            resourceOrder: 'icone',
            resourceAreaColumns: [
                {
                    field: 'etapa',
                    headerContent: 'Etapa',
                    width: 180,
                },
                {
                    field: 'dataInicio',
                    headerContent: 'Início'
                },
                {
                    field: 'dataFim',
                    headerContent: 'Fim'
                },
                {
                    field: 'prazoReal',
                    headerContent: 'Pz. Real',
                    cellDidMount: function (info) {
                        info.el.style.textAlign = "center";
                    }
                },
                {
                    field: 'prazoObjetivo',
                    headerContent: 'Pz. Previsto',
                    cellDidMount: function (info) {
                        info.el.style.textAlign = "center";
                    }
                },
                {
                    field: 'desvio',
                    headerContent: 'Pz. Vencido',
                    cellDidMount: function (info) {
                        info.el.style.textAlign = "center";
                    }
                },
                {
                    field: 'responsabilidade',
                    headerContent: 'Resp.'
                },
                {
                    field: 'executor',
                    headerContent: 'Exec.'
                }
            ],
            resources: this.resources,
            schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',

            events: this.events,
        };

        this.calendarVisible = true;
    }

    getAtividadeEtapa() {
        this.atividadeService.getAtividadeEtapa(this.idAtividade)
            .subscribe(
                (atvEtapa: any) => {
                    if (atvEtapa != null) {
                        for (let i = 0; i < atvEtapa.atividadeEtapaPlanejadaModel.length; i++) {
                            let childrens = [];
                            let id = createEventId();
                            for (let h = 0; h < atvEtapa.atividadeEtapaPlanejadaModel[i].historico.length; h++) {
                                childrens.push
                                    (
                                        {
                                            title: atvEtapa.atividadeEtapaPlanejadaModel[i].historico[h].situacao,
                                            etapa: atvEtapa.atividadeEtapaPlanejadaModel[i].historico[h].situacao,
                                            dataInicio: atvEtapa.atividadeEtapaPlanejadaModel[i].historico[h].dataInicio != null ? this.datepipe.transform(atvEtapa.atividadeEtapaPlanejadaModel[i].historico[h].dataInicio, 'dd/MM/yyyy') : null,
                                            dataFim: atvEtapa.atividadeEtapaPlanejadaModel[i].historico[h].dataFim == null ? (atvEtapa.atividadeEtapaPlanejadaModel[i].historico[h].dataInicio != null ? this.datepipe.transform(atvEtapa.atividadeEtapaPlanejadaModel[i].historico[h].dataInicio, 'dd/MM/yyyy') : null) : this.datepipe.transform(atvEtapa.atividadeEtapaPlanejadaModel[i].historico[h].dataFim, 'dd/MM/yyyy'),
                                            prazoReal: atvEtapa.atividadeEtapaPlanejadaModel[i].historico[h].prazoReal,
                                            prazoObjetivo: atvEtapa.atividadeEtapaPlanejadaModel[i].historico[h].prazoObjetivo,
                                            desvio: atvEtapa.atividadeEtapaPlanejadaModel[i].historico[h].prazoDias == null ? '' : atvEtapa.atividadeEtapaPlanejadaModel[i].historico[h].prazoDias.toString(),
                                            responsabilidade: atvEtapa.atividadeEtapaPlanejadaModel[i].historico[h].responsabilidade.replace('PREFEITURA', 'PREF.').replace('INDEFINIDO', 'INDEF.'),
                                            executor: atvEtapa.atividadeEtapaPlanejadaModel[i].historico[h].executor,
                                            color: atvEtapa.atividadeEtapaPlanejadaModel[i].historico[h].situacaoCor
                                        }
                                    );
                            }

                            this.resources.push(
                                {
                                    id: i,
                                    title: atvEtapa.atividadeEtapaPlanejadaModel[i].etapa,
                                    etapa: atvEtapa.atividadeEtapaPlanejadaModel[i].etapa,
                                    dataInicio: atvEtapa.atividadeEtapaPlanejadaModel[i].dataInicio != null ? this.datepipe.transform(atvEtapa.atividadeEtapaPlanejadaModel[i].dataInicio, 'dd/MM/yyyy') : null,
                                    dataFim: atvEtapa.atividadeEtapaPlanejadaModel[i].dataFim == null ? (atvEtapa.atividadeEtapaPlanejadaModel[i].dataInicio != null ? this.datepipe.transform(atvEtapa.atividadeEtapaPlanejadaModel[i].dataInicio, 'dd/MM/yyyy') : null) : this.datepipe.transform(atvEtapa.atividadeEtapaPlanejadaModel[i].dataFim, 'dd/MM/yyyy'),
                                    prazoReal: atvEtapa.atividadeEtapaPlanejadaModel[i].prazoReal,
                                    prazoObjetivo: atvEtapa.atividadeEtapaPlanejadaModel[i].prazoObjetivo,
                                    desvio: atvEtapa.atividadeEtapaPlanejadaModel[i].datasEtapa.length == 0 ? '' : atvEtapa.atividadeEtapaPlanejadaModel[i].desvio.toString(),
                                    responsabilidade: atvEtapa.atividadeEtapaPlanejadaModel[i].responsabilidade.map(function (resp: any) { return resp.nome }).join(', ').replace('PREFEITURA', 'PREF.').replace('INDEFINIDO', 'INDEF.'),
                                    children: childrens,
                                    cor: atvEtapa.atividadeEtapaPlanejadaModel[i].cor.replace('#DDDDDD', '#b0adad')
                                }
                            );

                            for (let j = 0; j < atvEtapa.atividadeEtapaPlanejadaModel[i].datasEtapa.length; j++) {

                                this.events.push(
                                    {
                                        id: createEventId(),
                                        resourceId: i,
                                        etapa: atvEtapa.atividadeEtapaPlanejadaModel[i].etapa,
                                        start: atvEtapa.atividadeEtapaPlanejadaModel[i].datasEtapa[j].dataInicio,
                                        end: atvEtapa.atividadeEtapaPlanejadaModel[i].datasEtapa[j].dataFim,
                                        className: 'item_event expected',
                                        color: atvEtapa.atividadeEtapaPlanejadaModel[i].cor,
                                        extendedProps: {
                                            description: ''
                                        }
                                    }
                                );
                            }
                        }

                        if (this.events.length > 0) {
                            let dataInicio = this.events.sort((a, b) => (a.start.localeCompare(b.end)))[0].start;
                            let dataFim = this.events.sort((a, b) => (a.end.localeCompare(b.end)))[this.events.length - 1].end;

                            this.duration = new Date(dataFim).getFullYear() - new Date(dataInicio).getFullYear();
                            this.duration = this.duration == 0 ? 1 : this.duration + 1;
                        }

                        this.loadCalendar();
                    }
                },
                err => {
                    console.log(err);
                }
            );
    }

    createEventId() {
        return String(this.eventGuid++);
    }
}